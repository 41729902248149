import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import { useGoogleLogin } from "@react-oauth/google";
import Cookies from "js-cookie";
import { useRouter } from "next/router";
import { Fragment, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import SocialLoginButton from "../../../components/onboarding/SocialLoginButton/SocialLoginButton";
import * as config from "../../../config/config";
import { getItem, setItem } from "../../../helpers/data_management";
import { posthogSignup } from "../../../helpers/posthog_management";
import { triggerSnackBar } from "../../../helpers/snackbar_management";
import { sendTrackingEvent } from "../../../helpers/tracking_management";
import useTriggerSnackbar from "../../../hooks/useTriggerSnackbar";
import {
  updateJobAlertVisitorEmailDialog,
  updateResumeConfirmationDialog,
} from "../../../redux/actions/dialog_action";
import { applyJob } from "../../../redux/actions/job_action";
import {
  hideSnackBar,
  showSnackBar,
} from "../../../redux/actions/snackbar_action";
import {
  globalLoginFunc,
  socialMediaLogin,
} from "../../../redux/actions/user_action";
import { store } from "../../../redux/stores/store";
import * as types from "../../../redux/types/user_type";
import SignUp from "../../onboarding/SignUp/SignUp";
import ForgotPassword from "../ForgotPassword/ForgotPassword";
import {
  ActionLabelStyled,
  CircularProgressStyled,
  CloseIconContainer,
  CustomDivider,
  CustomDividerContainer,
  DialogContentStyled,
  EmailContainer,
  EmailLoginFormContainer,
  ForgotPasswordContainer,
  FormTitle,
  GoogleButton,
  HeaderDialog,
  HiredlyLogoGrid,
  LabelDivider,
  LabelStyled,
  LinkedInButton,
  LogInButton,
  LoginDialog,
  LogoStyled,
  PasswordContainer,
  SSOLoginContainer,
  SignUpContainer,
  SocialSpinnerContainer,
  SubmissionContainer,
  TextInput,
  VisibilityIconStyled,
  VisibilityOffIconStyled,
} from "./styles";

let socialButtonRefs = {};

function TextInputFunction(props) {
  const {
    label,
    handleChange,
    type,
    email,
    password,
    onKeyDown,
    passwordShown,
    togglePassword,
    loggingIn,
  } = props;

  return (
    <Fragment>
      <TextInput
        disabled={loggingIn}
        label={type == "email" ? "Email" : "Password"}
        value={type == "email" ? email : password}
        type={`${type}`}
        onChange={handleChange}
        id={`filled-required-${label}`}
        variant="filled"
        onKeyDown={onKeyDown}
        password_shown={passwordShown ? "true" : "false"}
        InputProps={{
          endAdornment: (
            <InputAdornment
              position="start"
              style={{ display: `${type == "email" ? "none" : "flex"}` }}
            >
              {passwordShown ? (
                <VisibilityIconStyled onClick={togglePassword} />
              ) : (
                <VisibilityOffIconStyled onClick={togglePassword} />
              )}
            </InputAdornment>
          ),
        }}
      />
    </Fragment>
  );
}

function getAppId(ssoLogin) {
  switch (ssoLogin) {
    case "Continue with Google": {
      return process.env.GOOGLE_CLIENT_ID;
    }
    case "Continue with Linkedin": {
      return process.env.LINKEDIN_ID;
    }
    case "Continue with Facebook": {
      return process.env.FACEBOOK_APP_ID;
    }
  }
}

// Need to pass down redux action props
function LoginForm(props) {
  const {
    handleClickSignUp,
    loggingIn,
    handleOpenForgotPassword,
    updateJobAlertVisitorEmailDialog,
  } = props;
  const router = useRouter();
  const [passwordShown, setPasswordShown] = useState(false);

  // For Google login
  const [hideGoogleButton, setHideGoogleButton] = useState(false);

  const fypTab = Number(useSelector((state) => state.jobs.fypTab));
  const signUpOrigin = useSelector((state) => state?.navbar?.signUpOrigin);
  const selectedJob = useSelector((state) => state?.jobs?.job);
  const selectedJobId = useSelector((state) => state.jobs?.selectedJobId);
  const dispatch = useDispatch();
  const [triggerSnackbarFunc] = useTriggerSnackbar();

  const onFailure = (response) => {
    if (
      response.details?.includes(
        "Cookies are not enabled in current environment."
      )
    ) {
      setHideGoogleButton(true);
    }
  };

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const params = {
        _provider: "google",
        _token: {
          accessToken: tokenResponse.access_token,
        },
      };
      handleSocialLogin(params);
    },
    onError: (errorResponse) => onFailure(errorResponse),
  });

  // Password toggle handler
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  // Function to trigger job seekers who log in via email
  const triggerCustomEventEmailLogin = () => {
    sendTrackingEvent({ event: "email-login" });
  };

  // Function to trigger job seekers who log in via SSO
  const triggerCustomEventSsoLogin = (provider) => {
    sendTrackingEvent({
      event: `${provider == "google" ? "google-login" : "facebook-login"}`,
    });
  };

  // Function to trigger job seekers who sign up for the first time via SSO
  const triggerCustomEventSsoFirstTimeSignup = (provider) => {
    if (signUpOrigin === "job_recommendation" && fypTab === 1) {
      if (signUpOrigin === "jobAlert") {
        sendTrackingEvent({
          event: "CE_sign_up_job_alert",
          method: provider === "google" ? "google" : "facebook",
          origin: "job_alert",
        });
      } else {
        sendTrackingEvent({
          event: "CE_sign_up_rec",
          method: provider === "google" ? "google" : "facebook",
          origin: "job_recommendation",
        });
      }
    } else {
      sendTrackingEvent({
        event: `${
          provider == "google"
            ? "google-sso-first-time-signup"
            : "facebook-sso-first-time-signup"
        }`,
      });
    }
    posthogSignup(
      selectedJob,
      selectedJob?.company,
      signUpOrigin,
      provider === "google" ? "google" : "facebook"
    );
  };

  const [values, setValues] = useState({
    id: "123456789",
    email: router.query.hiredlyx ? router.query.email : "",
    password: "",
    error: "",
    loading: false,
    message: "",
  });

  const { id, email, password, loading, message, error } = values;

  const handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }

    if (email == "" || password == "") {
      const cParams = {
        message: "Please key in both email and password to log in",
        severity: "error",
      };
      triggerSnackBar(props.hideSnackBar, props.showSnackBar, cParams);

      return;
    }

    let params = {
      canCancel: true,
      email: email,
      password: password,
    };

    dispatch(globalLoginFunc(params)).then(async (response) => {
      let userId = response?.payload?.user?.id;

      if (response.type === types.NEW_SESSION_LOGIN_SUCCEED) {
        setValues({
          ...values,
          email: "",
          password: "",
        });
        props.handleClose();
        Cookies.set("userId", userId, { expires: 365 }); // Store user ID to cookies to be used by GA with 1 year expiry
        Cookies.set("isRegistered", userId ? true : false, { expires: 365 }); // Check if user is registered/logged in and store cookie for CleverTap

        triggerCustomEventEmailLogin();

        // check if visitor job alert exists and store to local storage
        if (store.getState().jobs.visitorJobAlert) {
          setItem(
            "visitorJobAlert",
            store.getState().jobs.visitorJobAlert.toString()
          );
        }

        localStorage.setItem("job_preference_clicked", 0);

        const emailHotJob = getItem("email-hot-job");

        if (emailHotJob) {
          localStorage.removeItem("email-hot-job");
          return router.push("/profile#for-you");
        }

        if (
          response.payload.user.resume === "" &&
          !response.payload.user.onboardingComplete
        ) {
          props.handleClose();
        }

        // if currently viewing a job, attempt to apply job without login,
        // this function will help to auto apply job after login
        // affected area: job list page AND single job page

        const targetJobId = selectedJobId ? selectedJobId : null;

        if (targetJobId) {
          const applyJobRes = dispatch(
            applyJob({
              jobId: targetJobId,
              canCancel: false,
              origin: "",
              jobDiscovery: "",
            })
          );

          if (applyJobRes.type === "APPLY_JOB_SUCCEED") {
            triggerSnackbarFunc({
              snackbarMessage: "Application successful!",
              severity: "success",
            });
            return router.reload();
          } else {
            triggerSnackbarFunc({
              snackbarMessage: "Application failed!",
              severity: "error",
            });
            return router.reload();
          }
        }

        router.reload();
      } else if (
        response.type === types.SESSION_LOGIN_FAILED &&
        response.invalid
      ) {
        let cParams = {
          message: "Invalid email or password.",
          severity: "error",
        };
        triggerSnackBar(props.hideSnackBar, props.showSnackBar, cParams);
      } else if (
        response.type === types.SESSION_LOGIN_FAILED_INVALID_EMAIL &&
        response.invalid
      ) {
        props.handleClickSignUp(true);
      } else {
        let cParams = {
          message: "Failed to login at the moment, please try again later.",
          severity: "error",
        };
        triggerSnackBar(props.hideSnackBar, props.showSnackBar, cParams);
      }
      updateJobAlertVisitorEmailDialog(false);
    });
  };

  const handleChange = (name) => (e) => {
    setValues({ ...values, error: false, [name]: e.target.value });
  };

  const getProvider = (ssoLogin) => {
    switch (ssoLogin) {
      case "Continue with Google": {
        return "google";
      }
      case "Continue with Linkedin": {
        return "facebook";
      }
      case "Continue with Facebook": {
        return "facebook";
      }
    }
  };

  const handleSocialLogin = (response) => {
    let provider = response._provider;

    // To track users who sign up from an advertisement
    let urlParams = document.cookie
      .split("; ")
      .find((row) => row.startsWith("params="))
      ?.toString()
      .replace("params=", "");

    let params = {
      provider: provider,
      accessToken: response._token.accessToken,
      urlParams: urlParams,
    };

    props.socialMediaLogin(params).then((response) => {
      try {
        if (socialButtonRefs[provider]) {
          socialButtonRefs[provider].props.triggerLogout();
        }
      } catch (error) {
        //
      }

      if (
        response.type === types.SOCIAL_LOGIN_FAILED &&
        response.payload?.noEmail === true
      ) {
        let cParams = {
          message: "Social login failed, please try again later.",
          severity: "error",
        };
        triggerSnackBar(props.hideSnackBar, props.showSnackBar, cParams);
      }

      if (response.type === types.SOCIAL_LOGIN_SUCCEED) {
        let userId = response.payload.user.id;
        let userName = response.payload.user.name;
        let userEmail = response.payload.user.email;

        // Check if visitor job alert exists and store to local storage
        if (store.getState().jobs.visitorJobAlert) {
          setItem(
            "visitorJobAlert",
            store.getState().jobs.visitorJobAlert.toString()
          );
        }

        Cookies.set("userId", userId, { expires: 365 }); // Store user ID to cookies to be used by GA with 1 year expiry
        Cookies.set("isRegistered", userId ? true : false, { expires: 365 }); // Check if user is registered/logged in and store cookie for CleverTap

        localStorage.setItem("job_preference_clicked", 0);

        if (store.getState().user.isDropCV) {
          if (response.payload && response.payload.newUser) {
            // Google enhanced conversion tracking for sign ups on drop resume page
            sendTrackingEvent({
              event: "event_enhanced_conversion",
              enhanced_conversion_data: {
                email: `${userEmail}`,
                phone_number: ``,
                last_name: ``,
                first_name: `${userName}`,
              },
            });

            triggerCustomEventSsoFirstTimeSignup(provider);
          }
          router.reload();
        } else if (response.payload && response.payload.newUser) {
          // Google enhanced conversion tracking for SSO sign ups
          sendTrackingEvent({
            event: "event_enhanced_conversion",
            enhanced_conversion_data: {
              email: `${userEmail}`,
              phone_number: ``,
              last_name: ``,
              first_name: `${userName}`,
            },
          });

          // Delay pushing GTM events to ensure CleverTap script has been initiated first
          triggerCustomEventSsoFirstTimeSignup(provider);
          props.handleClose();
        } else {
          triggerCustomEventSsoLogin(provider);
          if (
            response.payload.user.resume === "" &&
            !response.payload.user.onboardingComplete
          ) {
            props.handleClose();
            return dispatch(updateResumeConfirmationDialog(true));
          }

          const emailHotJob = getItem("email-hot-job");

          if (emailHotJob) {
            localStorage.removeItem("email-hot-job");
            return router.push("/profile#for-you");
          }

          router.reload();
        }
      }
      updateJobAlertVisitorEmailDialog(false);
    });
  };

  const handleSocialLoginFailure = (error) => {};

  const handleLinkedInButtonClick = () => {
    let oauthUrl = new URL("https://www.linkedin.com/oauth/v2/authorization");
    oauthUrl.searchParams.append("response_type", "code");
    oauthUrl.searchParams.append("client_id", process.env.LINKEDIN_ID);
    oauthUrl.searchParams.append(
      "redirect_uri",
      process.env.LINKEDIN_REDIRECT_URI
    );
    oauthUrl.searchParams.append("scope", process.env.LINKEDIN_SCOPE);

    var width = 450,
      height = 730,
      left = window.screen.width / 2 - width / 2,
      top = window.screen.height / 2 - height / 2;

    window.location = oauthUrl;

    // check if visitor job alert exists and store to local storage
    if (store.getState().jobs.visitorJobAlert) {
      setItem(
        "visitorJobAlert",
        store.getState().jobs.visitorJobAlert.toString()
      );
    }
    updateJobAlertVisitorEmailDialog(false);
  };

  const onKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSubmit(null);
    }
  };

  return (
    <Fragment>
      <DialogContentStyled>
        <FormTitle>Log In</FormTitle>
        <EmailLoginFormContainer>
          <form>
            <Grid container justifyContent="center">
              <EmailContainer item>
                <TextInputFunction
                  loggingIn={loggingIn}
                  label={"Email"}
                  type="email"
                  email={email}
                  handleChange={handleChange("email")}
                  onKeyDown={onKeyDown}
                  passwordShown={passwordShown}
                />
              </EmailContainer>
              <PasswordContainer item>
                <TextInputFunction
                  loggingIn={loggingIn}
                  label={"Password"}
                  type={passwordShown ? "text" : "password"}
                  password={password}
                  handleChange={handleChange("password")}
                  onKeyDown={onKeyDown}
                  passwordShown={passwordShown}
                  togglePassword={togglePassword}
                />
              </PasswordContainer>
            </Grid>
          </form>
        </EmailLoginFormContainer>
        <SubmissionContainer>
          <ForgotPasswordContainer>
            <ActionLabelStyled
              onClick={handleOpenForgotPassword}
              color="primary"
            >
              Forgot your password?
            </ActionLabelStyled>
          </ForgotPasswordContainer>
          <LogInButton
            onClick={handleSubmit}
            variant="contained"
            disabled={loggingIn}
          >
            Log In
          </LogInButton>
          <SignUpContainer>
            <LabelStyled $caption>Don't have an account?</LabelStyled>
            <ActionLabelStyled onClick={handleClickSignUp} color="primary">
              sign up
            </ActionLabelStyled>
          </SignUpContainer>
        </SubmissionContainer>
        <Fragment>
          <CustomDividerContainer>
            <CustomDivider />
            <LabelDivider>Or continue with</LabelDivider>
            <CustomDivider />
          </CustomDividerContainer>
          <SSOLoginContainer item>
            {props.socialLoggingIn || loggingIn ? (
              <SocialSpinnerContainer>
                <CircularProgressStyled />
              </SocialSpinnerContainer>
            ) : (
              <Fragment>
                <GoogleButton
                  onClick={googleLogin}
                  $hide_button={hideGoogleButton ? "true" : "false"}
                >
                  <img
                    src={
                      config.assetDomain +
                      "/images/hiredly/google-logo-login.svg"
                    }
                    alt="google"
                  />
                </GoogleButton>
                <LinkedInButton onClick={handleLinkedInButtonClick}>
                  <img
                    src={
                      config.assetDomain +
                      "/images/hiredly/linkedin-logo-login.svg"
                    }
                    alt="linkedin"
                  />
                </LinkedInButton>
                <SocialLoginButton
                  getInstancez={(node) => {
                    if (node) {
                      if (
                        socialButtonRefs[
                          getProvider("Continue with Facebook")
                        ] == undefined
                      ) {
                        socialButtonRefs[
                          getProvider("Continue with Facebook")
                        ] = node;
                      }
                    }
                  }}
                  provider={getProvider("Continue with Facebook")}
                  appId={getAppId("Continue with Facebook")}
                  onLoginSuccess={handleSocialLogin}
                  onLoginFailure={handleSocialLoginFailure}
                  // onLogoutSuccess={handleSocialLogout}
                  // onLogoutFailure={handleSocialLogout}
                >
                  <img
                    src={
                      config.assetDomain +
                      "/images/hiredly/facebook-logo-login.svg"
                    }
                    alt="facebook"
                  />
                </SocialLoginButton>
              </Fragment>
            )}
          </SSOLoginContainer>
        </Fragment>
      </DialogContentStyled>
    </Fragment>
  );
}

function Login(props) {
  const {
    open,
    handleClickSignUp,
    handleClickSignIn,
    handleClose,
    signUp,
    forgotPassword,
    handleOpenForgotPassword,
  } = props;

  return (
    <LoginDialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <HeaderDialog>
        <HiredlyLogoGrid>
          <LogoStyled
            src={config.assetDomain + "/images/landing/hiredly-logo.png"}
            alt="hiredly-logo"
            layout="fill"
          />
        </HiredlyLogoGrid>
        <CloseIconContainer onClick={handleClose}>
          <CloseIcon />
        </CloseIconContainer>
      </HeaderDialog>

      {forgotPassword ? (
        <ForgotPassword handleClose={handleClose} />
      ) : !signUp ? (
        <LoginForm
          handleClickSignUp={handleClickSignUp}
          login={props.login}
          loggingIn={props.loggingIn}
          socialMediaLogin={props.socialMediaLogin}
          hideSnackBar={props.hideSnackBar}
          showSnackBar={props.showSnackBar}
          handleOpenForgotPassword={handleOpenForgotPassword}
          handleClose={handleClose}
          socialLoggingIn={props.socialLoggingIn}
          signInText={props.signInText}
          updateJobAlertVisitorEmailDialog={
            props.updateJobAlertVisitorEmailDialog
          }
        />
      ) : (
        signUp && (
          <SignUp
            handleClose={handleClose}
            handleClickSignIn={handleClickSignIn}
          />
        )
      )}
    </LoginDialog>
  );
}

const mapStateToProps = (state) => {
  return {
    loggingIn: state.user.loggingIn,
    socialLoggingIn: state.user.socialLoggingIn,
    signInText: state.navbar.signInText,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    socialMediaLogin: bindActionCreators(socialMediaLogin, dispatch),
    hideSnackBar: bindActionCreators(hideSnackBar, dispatch),
    showSnackBar: bindActionCreators(showSnackBar, dispatch),
    updateJobAlertVisitorEmailDialog: bindActionCreators(
      updateJobAlertVisitorEmailDialog,
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
