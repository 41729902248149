import { jobTypes } from "../helpers/constant";

// for checking non-existent path
const check404 = (pathArr) => {
  for (const path of pathArr) {
    if (path === "jobs") {
      return false;
    }

    const cleanedPath = path?.replaceAll("-", " ");

    // checks for chinese character, and returns all chinese character into a single string
    const hanWords =
      cleanedPath?.match(
        /[\u3000-\u303F\u3400-\u4DBF\u4E00-\u9FFF]+(?=\W*[a-zA-Z])/g
      ) || [];

    if (Array?.isArray(hanWords) && hanWords?.length > 0) {
      return false;
    }

    // if there is no chinese characters, only proceed to match for roman characters
    const words = cleanedPath?.match(/\b\w+\b/g) || [];

    if (words?.length > 1) {
      if (
        cleanedPath?.startsWith("in") ||
        cleanedPath?.startsWith("jobs") ||
        cleanedPath?.endsWith("jobs")
      ) {
        return false;
      }
    }

    for (const type of jobTypes) {
      if (path?.includes(type?.slug)) {
        return false;
      }
    }
  }

  return true;
};

const checkCompany404 = (pathArr, companySizesList) => {
  for (const path of pathArr) {
    const cleanedPath = path?.replaceAll("-", " ");

    if (cleanedPath?.startsWith("in")) {
      return false;
    }

    for (const size of companySizesList) {
      if (path?.includes(size?.slug)) {
        return false;
      }
    }
  }

  return true;
};

const checkNoIndex = (input, filterParams) => {
  if (!input || (!filterParams?.keyword && !filterParams?.trackIds)) return;

  if (
    (input?.experience && input?.experience?.length > 0) ||
    (input?.salary && input?.salary?.length > 0) ||
    (input?.specialisation && input?.specialisation?.length > 0) ||
    (filterParams?.keyword?.length > 0 && filterParams?.trackIds?.length > 0)
  ) {
    return true;
  }
  return false;
};

const getTitle = (filterParams, specialisationList) => {
  let subheaderTitle = null;

  if (Object?.keys(filterParams)?.length > 1 || filterParams?.keyword) {
    return null;
  }

  if (filterParams?.specialisation && specialisationList?.length > 0) {
    const specialisation = filterParams?.specialisation?.map((id) => {
      return specialisationList?.find((spec) =>
        spec?.subSpecialisations?.some((sub) => sub?.id === id)
      );
    });

    return specialisation?.length > 1
      ? null
      : { title: specialisation[0]?.name };
  }

  if (filterParams?.state) {
    const key = "state";
    return { title: filterParams?.state, key };
  }

  if (filterParams?.jobType) {
    const jobType = filterParams?.jobType?.map((id) => {
      return jobTypes?.find((jobType) => jobType?.id === id);
    });
    return jobType?.length > 1 ? null : { title: jobType[0]?.jobType };
  }

  return subheaderTitle;
};

const convertUrlToIds = (query, specialisationList, stateList) => {
  const result = {};

  if (specialisationList?.length === 0 || stateList?.length === 0) {
    return result;
  }

  const { filter, specialisation, state, jobType, experience, salary } = query;

  // Helper to safely parse and filter integer arrays
  const toIntArray = (arr) =>
    Array.isArray(arr)
      ? (arr?.map(Number) ?? []).filter(Number.isInteger)
      : [parseInt(arr)];

  if (state) {
    const stateValues = Array.isArray(state) ? state : [state];
    result.state = stateValues
      .map((stateValue) => {
        const matchedState = stateList.find(
          (s) => s.slug === stateValue || s.state === stateValue
        );
        return matchedState?.state;
      })
      .filter(Boolean);
  }

  // Process query parameters
  if (specialisation) result.specialisation = toIntArray(specialisation);
  if (jobType) result.jobType = toIntArray(jobType);
  if (experience) result.experience = toIntArray(experience);
  if (salary) {
    const parsed = Number(salary);
    if (Number.isInteger(parsed)) result.salary = parsed;
  }

  // Clean slugs using regex to replace multiple patterns
  const cleanedSlug = filter?.map((slug) =>
    slug.replace(/^(jobs-in-|in-)|-jobs$/g, "")
  );

  // Precompute specialisation lookups
  const [specMap, subSpecMap] = specialisationList?.reduce(
    (maps, spec) => {
      const subIds = spec.subSpecialisations?.map((sub) => sub.id) || [];
      maps[0].set(spec.slug, subIds);
      spec.subSpecialisations?.forEach((sub) => maps[1].set(sub.slug, sub.id));
      return maps;
    },
    [new Map(), new Map()]
  ) ?? [new Map(), new Map()];

  // Process cleaned slugs
  cleanedSlug?.forEach((slug) => {
    if (slug === "global-hire") {
      result.globalHire = true;
      return;
    }

    const jobMatch = jobTypes.find((type) => slug.includes(type.slug));
    if (jobMatch) {
      result.jobType = [jobMatch.id];
      return;
    }

    const stateMatch = stateList.find((state) => slug.includes(state.slug));
    if (stateMatch) {
      result.state = [stateMatch.state];
      return;
    }

    if (specMap.has(slug)) {
      result.specialisation = specMap.get(slug);
    } else if (subSpecMap.has(slug)) {
      result.specialisation = [subSpecMap.get(slug)];
    } else if (slug !== "jobs") {
      // Skip "jobs" as a keyword
      result.keyword = slug;
    }
  });

  return result;
};

const convertIdsToUrl = (params, specialisationList, stateList) => {
  if (!params) return { path: "/jobs", query: "" };

  const {
    globalHire,
    keyword,
    specialisation,
    state,
    jobType,
    experience,
    salary,
  } = params;

  if (
    !globalHire &&
    !keyword?.length &&
    !specialisation?.length &&
    !state?.length &&
    !jobType?.length &&
    !experience?.length &&
    (salary == null || salary === 0)
  ) {
    return { path: "/jobs", query: "" };
  }

  const pathSegments = [];
  const queryParams = [];

  // Helper function to add query parameters
  const addQueryParams = (key, values) => {
    if (key === "state") {
      values.forEach((value) =>
        queryParams.push(`${key}=${value.toLowerCase().replace(/\s+/g, "-")}`)
      );
    } else {
      values.forEach((value) => queryParams.push(`${key}=${value}`));
    }
  };

  // Process keyword
  if (keyword) {
    // Encode the keyword to handle special characters
    const encodedKeyword = encodeURIComponent(
      keyword.toLowerCase().replace(/\s+/g, "-")
    );
    pathSegments.push(`${encodedKeyword}-jobs`);
  }

  // Process specialisation
  if (specialisation) {
    const parentSpec = specialisationList.find((spec) =>
      specialisation.every((id) =>
        spec.subSpecialisations.some((sub) => sub.id === id)
      )
    );

    if (parentSpec) {
      const selectedSubs = parentSpec.subSpecialisations.filter((sub) =>
        specialisation.includes(sub.id)
      );

      if (selectedSubs.length === parentSpec.subSpecialisations.length) {
        pathSegments.push(`${keyword ? "in" : "jobs-in"}-${parentSpec.slug}`);
      } else if (selectedSubs.length === 1) {
        pathSegments.push(
          `${keyword ? "in" : "jobs-in"}-${parentSpec.slug}`,
          selectedSubs[0].slug
        );
      } else {
        addQueryParams("specialisation", specialisation);
      }
    } else {
      addQueryParams("specialisation", specialisation);
    }
  }

  // Process state
  if (state) {
    if (state.length === 1) {
      const stateSlug = stateList.find((s) => s.state === state[0])?.slug;
      if (stateSlug) {
        pathSegments.push(
          `${keyword || pathSegments.length ? "in" : "jobs-in"}-${stateSlug}`
        );
      }
    } else {
      addQueryParams("state", state);
    }
  }

  // Process jobType
  if (jobType) {
    if (jobType.length === 1) {
      const typeSlug = jobTypes.find((t) => t.id === jobType[0])?.slug;
      if (typeSlug) {
        pathSegments.push(pathSegments.length ? typeSlug : `${typeSlug}-jobs`);
      }
    } else {
      addQueryParams("jobType", jobType);
    }
  }

  // Process experience and salary
  if (experience) addQueryParams("experience", experience);
  if (salary) queryParams.push(`salary=${salary}`);

  // Process globalHire
  if (globalHire) {
    pathSegments.unshift(
      pathSegments.length ? "global-hire" : "global-hire-jobs"
    );
  }

  // Construct final URL
  const path = pathSegments.length ? `/${pathSegments.join("/")}` : "";
  const query = queryParams.length ? `${queryParams.join("&")}` : "";

  // Add /jobs base path when there are query params but no path segments
  const finalPath = path || (query ? "/jobs" : "");

  return { path: finalPath, query };
};

const encodePage = (page, itemPerPage = 30) => {
  if (!page) {
    return null;
  }
  const pageCount = Number(page) * itemPerPage;

  return btoa(pageCount);
};

const decodePage = (pageCode, itemPerPage = 30) => {
  if (!pageCode) {
    return null;
  }
  const pageCount = atob(pageCode);
  return Math.floor(pageCount / itemPerPage);
};

const convertCompanyUrlToIds = (
  query,
  industriesList,
  locationList,
  companySizesList
) => {
  if (!query) return null;

  const result = {};

  const slugArr = query?.filter;

  // define query params
  const industryQuery = query?.industry_ids;
  const locationQuery = query?.state_regions;
  const companySizeQuery = query?.company_sizes;

  // // remove "in-" or "jobs-in-" from slug
  // const cleanedSlugs = slugArr??.map((slug) => slug?.replace(/^(in-|with-)/, ""));

  // // create map for slug to specialisation, sub specialisation and state
  // const slugToIndustry = new Map();
  // const slugToLocation = new Map();
  // const slugToCompanySize = new Map();

  // // loop through state region list to create map
  // industriesList?.forEach((region) => {
  //   slugToIndustry?.set(region?.slug, region);
  // });

  // // loop through state region list to create map
  // locationList?.forEach((region) => {
  //   slugToLocation?.set(region?.slug, region);
  // });

  // // loop through job type list to create map
  // companySizesList?.forEach((type) => {
  //   slugToCompanySize?.set(type?.slug, type);
  // });

  // // loop through current slug to check if there any filter applied
  // cleanedSlugs??.forEach((slug, index) => {
  //   // check if any slug has match with any filter
  //   const industryMatch = slugToIndustry?.get(slug);
  //   const locationMatch = slugToLocation?.get(slug);
  //   const companySizeMatch = slugToCompanySize?.get(slug);

  //   // if industry exist, add it to industry
  //   if (industryMatch) {
  //     result.industry_ids = [parseInt(industryMatch?.id)];
  //   }

  //   // if state exist, add it to stateRegions
  //   if (locationMatch) {
  //     result.state_regions = [locationMatch?.state];
  //   }

  //   // if company size exist, add it to company size
  //   if (companySizeMatch) {
  //     result.company_sizes = [parseInt(companySizeMatch?.id)];
  //   }
  // });

  if (industryQuery) {
    result.industry_ids = Array?.isArray(industryQuery)
      ? industryQuery?.map((id) => parseInt(id))
      : [parseInt(industryQuery)];
  }

  if (locationQuery) {
    result.state_regions = Array?.isArray(locationQuery)
      ? locationQuery
      : [locationQuery];
  }

  if (companySizeQuery) {
    result.company_sizes = Array?.isArray(companySizeQuery)
      ? companySizeQuery?.map((id) => parseInt(id))
      : [parseInt(companySizeQuery)];
  }

  return result;
};

const getSelectedTab = (filterParams, currentPage, defaultFyp = "") => {
  if (Object.keys(filterParams).length > 0 || currentPage !== null) {
    return "job-list";
  }

  return defaultFyp === "1" ? "for-you" : "job-list";
};

const processJobSearchKeyword = (
  keyword,
  currentFilter,
  specialisationList,
  stateList,
  jobTypeList
) => {
  const selectedFilter = {
    ...currentFilter,
    keyword: keyword.trim(),
  };

  let foundMatchingResults = false;

  // Check for "global hire"
  if (keyword.toLowerCase() === "global hire") {
    selectedFilter.keyword = "";
    selectedFilter.globalHire = true;
    foundMatchingResults = true;
  }

  // Find matching specialisation
  const matchingSpecialisation = specialisationList.find(
    (specialisation) =>
      specialisation.name.toLowerCase() === keyword.toLowerCase()
  );

  // Find matching sub-specialisation
  const matchingSubSpecialisation = specialisationList.find((specialisation) =>
    specialisation.subSpecialisations.some(
      (subSpecialisation) =>
        subSpecialisation.name.toLowerCase() === keyword.toLowerCase()
    )
  );

  // Find matching state
  const matchingState = stateList.find(
    (state) => state.state.toLowerCase() === keyword.toLowerCase()
  );

  // Find matching job type
  const matchingJobType = jobTypeList.find((jobType) => {
    const jobTypeString = jobType.title.replace("-", " ").toLowerCase();
    return jobTypeString === keyword.toLowerCase();
  });

  // Initialize specialisation array if needed
  if (!selectedFilter.specialisation) {
    selectedFilter.specialisation = [];
  }

  // Process matching specialisation
  if (matchingSpecialisation) {
    selectedFilter.keyword = "";

    matchingSpecialisation.subSpecialisations.forEach((subSpecialisation) => {
      if (!selectedFilter.specialisation.includes(subSpecialisation.id)) {
        selectedFilter.specialisation.push(subSpecialisation.id);
      }
    });
    foundMatchingResults = true;
  }

  // Process matching sub-specialisation
  if (matchingSubSpecialisation) {
    selectedFilter.keyword = "";

    const matchingSubSpec = matchingSubSpecialisation.subSpecialisations.find(
      (subSpecialisation) =>
        subSpecialisation.name.toLowerCase() === keyword.toLowerCase()
    );

    if (
      matchingSubSpec &&
      !selectedFilter.specialisation.includes(matchingSubSpec.id)
    ) {
      selectedFilter.specialisation.push(matchingSubSpec.id);
    }

    foundMatchingResults = true;
  }

  // Process matching state
  if (matchingState) {
    selectedFilter.keyword = "";

    if (!selectedFilter.state) {
      selectedFilter.state = [];
    }

    if (!selectedFilter.state.includes(matchingState.state)) {
      selectedFilter.state.push(matchingState.state);
    }

    foundMatchingResults = true;
  }

  // Process matching job type
  if (matchingJobType) {
    selectedFilter.keyword = "";

    if (!selectedFilter.jobType) {
      selectedFilter.jobType = [];
    }

    if (!selectedFilter.jobType.includes(parseInt(matchingJobType.id))) {
      selectedFilter.jobType.push(parseInt(matchingJobType.id));
    }

    foundMatchingResults = true;
  }

  return {
    updatedFilter: selectedFilter,
    foundMatchingResults,
  };
};

export {
  check404,
  checkCompany404,
  checkNoIndex,
  convertCompanyUrlToIds,
  convertIdsToUrl,
  convertUrlToIds,
  decodePage,
  encodePage,
  getSelectedTab,
  getTitle,
  processJobSearchKeyword,
};
