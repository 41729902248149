import { Checkbox, FormControlLabel } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { isEmpty } from "lodash";
import { useRouter } from "next/router";
import { Fragment, useEffect, useLayoutEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
import { assetDomain } from "../../../config/config";
import { sendTrackingEvent } from "../../../helpers/tracking_management";
import useTriggerSnackbar from "../../../hooks/useTriggerSnackbar";
import {
  showInfoDialogActivelyAvailable,
  updateProfilePreviewDialog,
  updateResumeConfirmationDialog,
  updateSignUpProfileDialog,
} from "../../../redux/actions/dialog_action";
import { applyJob } from "../../../redux/actions/job_action";
import { updateOnboardingInfo } from "../../../redux/actions/user_action";
import { DirectSignUpFormUserParams } from "../../jobs/SecondaryVisitor/BaseDialog/types";
import FormTextField from "../../jobs/SecondaryVisitor/FormTextField/FormTextField";
import ResumeDropzone from "../../jobs/SecondaryVisitor/ResumeDropzone/ResumeDropzone";
import ActivelyAvailableDialog from "../../profile/ActivelyAvailableDialog/ActivelyAvailableDialog";
import SharedExitDialog from "../../shared/SharedExitDialog/SharedExitDialog";
import {
  BodyContactCaption,
  CheckIcon,
  CheckIdleIcon,
  CheckLabel,
  CheckedIcon,
  DialogActionsStyled,
  DialogContentStyled,
  DialogContentWrapper,
  DialogStyled,
  DialogTitleContainer,
  DialogTitleContent,
  DialogTitleStyled,
  EmptyContainer,
  HeaderCaption,
  LoadingButtonStyled,
  ResumeDropzoneText,
  WhiteButton,
  useStyles,
} from "./styles";

export default function SignUpProfileDialog(props: any) {
  const dispatch: any = useDispatch();
  const router = useRouter();
  const [triggerSnackbarFunc] = useTriggerSnackbar();
  const open = useSelector(
    (state: any) => state?.dialog?.showSignUpProfileDialog
  );
  const selectedJobId = useSelector((state: any) => state.jobs?.selectedJobId);
  const externalJobUrl = useSelector(
    (state: any) => state.jobs?.job?.externalJobUrl
  );

  const user = useSelector((state: any) => state?.user?.user);
  // const resume = useSelector((state: any) => state?.user?.resume);
  const mobile = useMediaQuery("(max-width:768px)");
  const [ref, inView, _] = useInView({ threshold: mobile ? 0.2 : 1 });
  const forms = ["Name", "Mobile Number", "Nationality"];

  const resumeExist =
    Object.keys(user).length > 0 &&
    user?.resume !== "" &&
    user?.state !== "no_resume";

  const [resumeUploaded, setResumeUploaded] = useState(resumeExist);
  const [resume, setResume] = useState(user?.resume ?? "");
  const [exist, setExist] = useState(false);
  const [formState, setFormState] = useState<"pending" | "loading" | "success">(
    "pending"
  );
  const [openForJob, setOpenForJob] = useState(true);
  const [showSwitchContainer, setShowSwitchContainer] = useState(false);
  const [userFormData, setUserFormData] = useState<DirectSignUpFormUserParams>({
    name: "",
    validName: true,
    phone: "",
    validPhone: true,
    nationality: "",
    validNationality: false,
    isFreshGrad: false,
  });
  const [isEmptyNameField, setIsEmptyNameField] = useState(false);
  const [isEmptyPhoneField, setIsEmptyPhoneField] = useState(false);
  const [isEmptyNationalityField, setIsEmptyNationalityField] = useState(false);
  const [triggerExitDialog, setTriggerExitDialog] = useState(false);
  const [invalidStyling, setInvalidStyling] = useState(false);

  const confettiGif = `${assetDomain}/gifs/confetti.gif`;
  const classes = useStyles();

  const userDetailsFulfilled =
    isEmpty(user?.name) ||
    (isEmpty(user?.mobileNumber) &&
      isEmpty(user?.nationality) &&
      isEmpty(resumeUploaded));

  const isSignUpProfileDialogOpen = sessionStorage.getItem(
    "triggerSignUpProfileDialog"
  );

  useEffect(() => {
    if (!isEmpty(user?.resume)) {
      setResumeUploaded(true);
      setResume(user?.resume);
    }
  }, [user]);

  useEffect(() => {
    if (!isEmpty(user)) {
      if (userDetailsFulfilled && isSignUpProfileDialogOpen) {
        setTimeout(() => {
          dispatch(updateSignUpProfileDialog(true));
        }, 1000);
      } else {
        dispatch(updateSignUpProfileDialog(false));
      }
    }
  }, [user, userDetailsFulfilled]);

  useLayoutEffect(() => {
    setShowSwitchContainer(inView ? false : true);
  }, [inView]);

  function validateEmptyFields() {
    if (!userFormData.name) {
      setIsEmptyNameField(true);
    } else {
      setIsEmptyNameField(false);
    }

    if (!userFormData.phone) {
      setIsEmptyPhoneField(true);
    } else {
      setIsEmptyPhoneField(false);
    }

    if (!userFormData.nationality) {
      setIsEmptyNationalityField(true);
    } else {
      setIsEmptyNationalityField(false);
    }
  }

  async function handleSubmit() {
    const invalidProfileDetails =
      !userFormData.name ||
      !userFormData.phone ||
      !userFormData.nationality ||
      !userFormData.validName ||
      !userFormData.validPhone ||
      !userFormData.validNationality;

    validateEmptyFields();

    if (invalidProfileDetails) {
      setInvalidStyling(true);
      return;
    }

    setFormState("loading");

    const userProfile = {
      name: userFormData.name,
      mobileNumber: userFormData.phone,
      nationality: userFormData.nationality,
      freshGrad: userFormData.isFreshGrad,
      email: user.email,
    };

    // After profile data is present, update user profile
    dispatch(updateOnboardingInfo(userProfile)).then(
      (updateProfileRes: any) => {
        if (updateProfileRes?.type === "UPDATE_ONBOARDING_INFO_FAILED") {
          triggerSnackbarFunc({
            snackbarMessage: "Something went wrong, please try again later.",
            severity: "error",
          });
        } else if (
          updateProfileRes?.type === "UPDATE_ONBOARDING_INFO_SUCCEED"
        ) {
          const { user } = updateProfileRes || {};
          // // identify user on posthog after is done onboarding
          // posthog.capture("update_user_properties", {
          //   distinctId: user?.id,

          //   $set: {
          //     uuid: user?.id,
          //     age: parseInt(user?.age ?? 0),
          //     gender: user?.gender,
          //     nationality: user?.nationality,
          //     is_actively_available: user?.activelyAvailable,
          //     has_resume: user?.resume ? true : false,
          //     current_location: user?.currentLocation,
          //     is_fresh_grad: user?.freshGrad,
          //     is_onboarding_completed: user?.onboardingComplete,
          //     signup_platform_device_os: user?.signupPlatformDeviceOs,
          //     track_ids: user?.trackIds,
          //   },
          // });

          sendTrackingEvent({
            event: "CE_onboarding_finish_click",
            origin: "onboarding",
          });
          // posthog.capture("onboarding_complete_profile_click", {
          //   origin: "resume_onboarding",
          //   step: "complete_signup",
          // });
          if (selectedJobId) {
            // Auto apply job
            dispatch(applyJob({ jobId: selectedJobId, canCancel: false })).then(
              (applyJobRes: any) => {
                if (applyJobRes.type === "APPLY_JOB_SUCCEED") {
                  if (externalJobUrl) {
                    window.open(externalJobUrl, "_blank");
                  }

                  setFormState("success");
                  sessionStorage.removeItem("triggerSignUpProfileDialog");
                  dispatch(updateSignUpProfileDialog(false));
                } else {
                  triggerSnackbarFunc({
                    snackbarMessage:
                      "Failed to auto apply job for you, please apply it again with your account.",
                    severity: "error",
                  });
                }
              }
            );
          } else {
            setFormState("success");
            sessionStorage.removeItem("triggerSignUpProfileDialog");
            dispatch(updateSignUpProfileDialog(false));
            router.push("/jobs");
          }
        } else {
          triggerSnackbarFunc({
            snackbarMessage: "Something went wrong, please try again later.",
            severity: "error",
          });
        }
      }
    );
  }
  //   });
  // }

  function isValidUserFormData() {
    return (
      userFormData.validName &&
      userFormData.validPhone &&
      userFormData.validNationality &&
      userFormData.name.length > 0 &&
      isPossiblePhoneNumber(userFormData.phone) &&
      resumeUploaded
    );
  }

  function handleCloseDialog() {
    dispatch(updateSignUpProfileDialog(false));
    // router.reload();
  }

  function handleCompleteProfileClick() {
    dispatch(updateSignUpProfileDialog(false));
    dispatch(updateProfilePreviewDialog(true));
    // posthog.capture("onboarding_complete", {
    //   origin: "resume_onboarding",
    // });
  }

  function handleExploreJobsClick() {
    dispatch(updateSignUpProfileDialog(false));
    sendTrackingEvent({
      event: "CE_click-explore-jobs-short-onboarding",
    });
    // posthog.capture("onboarding_explore_jobs_click", {
    //   origin: "resume_onboarding",
    // });
    router.push("/jobs");
  }

  function handleDoThisLaterClick() {
    // trigger exit dialog if user has filled in some info or uploaded resume
    if (
      !isEmpty(userFormData.name) ||
      !isEmpty(userFormData.phone) ||
      !isEmpty(userFormData.nationality) ||
      !isEmpty(resume)
    ) {
      setTriggerExitDialog(true);
    } else {
      dispatch(updateSignUpProfileDialog(false));
      sendTrackingEvent({
        event: "CE_onboarding_skip_click",
        origin: "onboarding",
      });
      sessionStorage.removeItem("triggerSignUpProfileDialog");
      router.push("/jobs");
    }
  }

  function handleBackClick() {
    dispatch(updateSignUpProfileDialog(false));
    dispatch(updateResumeConfirmationDialog(true));
  }

  function handleCheckboxClick() {
    setUserFormData((prev) => ({
      ...prev,
      isFreshGrad: !prev.isFreshGrad,
    }));
  }

  function handleInfoIconClick() {
    dispatch(showInfoDialogActivelyAvailable(true));
  }

  function toggleSwitch() {
    setOpenForJob((prev) => !prev);
  }

  return (
    userDetailsFulfilled && (
      <>
        <DialogStyled
          invalidProfileStyling={invalidStyling}
          open={open}
          success={formState === "success"}
        >
          <ActivelyAvailableDialog />
          {/* Header */}
          <DialogTitleContainer>
            <DialogTitleStyled>
              <DialogTitleContent>
                <CheckIcon />
                Tell us about yourself
              </DialogTitleContent>

              <HeaderCaption>
                Share your basic details and upload your resume to get started.
              </HeaderCaption>
            </DialogTitleStyled>
          </DialogTitleContainer>

          {/* Content */}
          <DialogContentStyled className={classes.scrollBar}>
            <DialogContentWrapper>
              <Fragment>
                <BodyContactCaption>Contact Details</BodyContactCaption>
                {forms.map((form, index) => {
                  return (
                    <FormTextField
                      key={`form-textfield-${index}`}
                      name={form}
                      userParams={userFormData}
                      setUserParams={setUserFormData}
                      exist={exist}
                      isEmptyNameField={isEmptyNameField}
                      setIsEmptyNameField={setIsEmptyNameField}
                      isEmptyPhoneField={isEmptyPhoneField}
                      setIsEmptyPhoneField={setIsEmptyPhoneField}
                      isEmptyNationalityField={isEmptyNationalityField}
                      setIsEmptyNationalityField={setIsEmptyNationalityField}
                    />
                  );
                })}
                <FormControlLabel
                  sx={{ height: "32px", marginBottom: "1rem" }}
                  control={
                    <Checkbox
                      onClick={handleCheckboxClick}
                      checkedIcon={<CheckedIcon />}
                      icon={<CheckIdleIcon />}
                    />
                  }
                  label={<CheckLabel>I’m a fresh graduate/ student</CheckLabel>}
                />
                <EmptyContainer>
                  <ResumeDropzoneText>Add Resume</ResumeDropzoneText>
                  <ResumeDropzone
                    ref={ref}
                    complete={resumeUploaded}
                    setComplete={setResumeUploaded}
                    resume={resume}
                    setResume={setResume}
                  />
                </EmptyContainer>
              </Fragment>
            </DialogContentWrapper>
          </DialogContentStyled>

          {/* Action */}
          <DialogActionsStyled>
            <Fragment>
              <WhiteButton onClick={handleDoThisLaterClick}>
                I'll do this later
              </WhiteButton>
              <LoadingButtonStyled
                loading={formState === "loading"}
                onClick={handleSubmit}
              >
                {formState === "loading" ? "" : "Continue"}
              </LoadingButtonStyled>
            </Fragment>
          </DialogActionsStyled>
        </DialogStyled>
        {/* for showing exit confirmation if user has resume/info but decides to drop off */}
        <SharedExitDialog
          triggerExitDialog={triggerExitDialog}
          setTriggerExitDialog={setTriggerExitDialog}
        />
      </>
    )
  );
}
