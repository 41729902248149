import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import ErrorIcon from "@mui/icons-material/Error";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Snackbar from "@mui/material/Snackbar";
import { styled as styling } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import withTheme from "@mui/styles/withTheme";
import styled from "styled-components";
import Color from "../../../assets/colors";
import { FontSize } from "../../../assets/font";

// Snackbar Component //
export const SnackbarStyled = styling(Snackbar)((props) => ({
  //
}));

export const SnackBarContainer = styling("div")((props) => ({
  position: "relative",
  padding: "0.5rem 1rem",
  backgroundColor: Color.white,
  width: props.$fullWidth === true ? "100%" : "15.5rem",
  borderRadius: "0.5rem",
  display: "flex",
  flexDirection: "column",
  boxShadow: "0px 0px 13px 1px rgba(151, 147, 147, 0.75)",
  "-webkit-box-shadow": "0px 0px 13px 1px rgba(151, 147, 147, 0.75)",
  "-moz-box-shadow": "0px 0px 13px 1px rgba(151, 147, 147, 0.75)",
  width: "335px",
  border:
    props.$severity === "success" ? "1px solid #90b938" : "1px solid #F75443",
}));

// continue to use styled component if there is a need for transient props
export const SnackbarAccent = withTheme(styled(Box)`
  && {
    position: absolute;
    width: 0.25rem;
    background-color: ${(props) =>
      props.$severity === "success"
        ? Color.success
        : props.$severity === "error"
        ? Color.error
        : props.$severity === "warning"
        ? Color.warn
        : Color.hiredlyPurple};
    height: 100%;
    border-radius: 0.5rem 0 0 0.5rem;
    left: 0;
    top: 0;
  }
`);

export const SeverityContainer = styling("div")((props) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
}));

export const SnackbarCheckCircleIconStyled = withTheme(styled(CheckCircleIcon)`
  && {
    color: ${(props) =>
      props.$severity === "ashley" ? Color.hiredlyPurple : Color.success};
    width: 1rem;
    height: 1rem;
  }
`);

export const SnackbarErrorCircleIconStyled = styling(ErrorIcon)((props) => ({
  color: props.$severity === "warning" ? Color.warn : Color.error,
  width: "1rem",
  height: "1rem",
}));

export const SnackbarSeverityText = styling(Typography)((props) => ({
  fontWeight: "700",
  paddingLeft: "0.5rem",
}));

export const SnackbarIconButtonStyled = styling(IconButton)((props) => ({
  //
}));

export const LinkStyled = styling(Link)((props) => ({
  color: Color.hiredlyPurple,
  textTransform: "capitalize",
  fontSize: FontSize.desktopCaption,
}));

export const SnackbarMessageText = styling(Typography)((props) => ({
  color: Color.darkGrey,
  marginTop: "0.25rem",
  fontSize: "1rem",
}));

export const CloseIconStyled = styling(CloseIcon)((props) => ({
  width: "1rem",
  height: "1rem",
}));
