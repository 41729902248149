import {
  Bars3Icon,
  ClockIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import { Drawer, useMediaQuery } from "@mui/material";
import { useClickAway } from "@uidotdev/usehooks";
import Cookies from "js-cookie";
import { debounce, isEmpty } from "lodash";
import { useRouter } from "next/router";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Color from "../../../../assets/colors";
import { getItem, setItem } from "../../../../helpers/data_management";
import {
  convertIdsToUrl,
  processJobSearchKeyword,
} from "../../../../helpers/filter_management";
import { handleSwitchCountry } from "../../../../helpers/link_management";
import { setEmployerParams } from "../../../../helpers/multicountry_management";
import { sendTrackingEvent } from "../../../../helpers/tracking_management";
import {
  clearCompanyList,
  updateCompanyFilter,
  updateCompanySearchKeyword,
} from "../../../../redux/actions/company_action";
import {
  clearSearchSuggestions,
  fetchSearchSuggestions,
  getTrendingJobKeywords,
  updateJobListFilter,
  updateJobListSearchKeyword,
  updateJobSearchKeyword,
} from "../../../../redux/actions/job_action";
import {
  updateShowMobileFilter,
  updateShowSignInModalStatus,
  updateSignInModalSignUpStatus,
  updateSignUpOrigin,
} from "../../../../redux/actions/navbar_action";
import {
  checkAuthentication,
  checkPDPAStatus,
  getUser,
  hydrateUserObject,
  logout,
  newGetUserInfo,
  updateUserProfileDrawerIndex,
} from "../../../../redux/actions/user_action";
import { store } from "../../../../redux/stores/store";
import * as types from "../../../../redux/types/user_type";
import WithOnboarding from "../../../hoc/WithOnboarding";
import Login from "../../../onboarding/Login/Login";
import ProfilePreviewDialog from "../../../onboarding/ProfilePreviewDialog/ProfilePreviewDialog";
import SignUpProfileDialog from "../../../onboarding/SignUpProfileDialog/SignUpProfileDialog";
import SharedJobPreferenceNotification from "../../SharedJobPreferenceNotification/SharedJobPreferenceNotification";
import SharedMobileFilterDialog from "../../SharedMobileFilterDialog/SharedMobileFilterDialog";
import SharedPrivacyDialog from "../../SharedPrivacyDialog/SharedPrivacyDialog";
import SharedSnackbar from "../../SharedSnackbar/SharedSnackbar";
import {
  AppbarStyled,
  AvatarStyled,
  CTAContainer,
  ChevronDownStyled,
  ChevronUpStyled,
  ChipStyled,
  ClickAwayListenerStyled,
  CompanyIcon,
  CountryGrowStyled,
  CountryMenuItem,
  CountryOptionName,
  CountryText,
  DividerLine,
  DrawerContentContainer,
  DrawerCountryText,
  DrawerCountryToggleGrid,
  DrawerDropdownRadio,
  DrawerLoginButton,
  DrawerSignUpButton,
  GrowStyled,
  HiredlyLogo,
  HomepageButton,
  HomepageButtonContainer,
  HorizontalDividerLine,
  IconButtonStyled,
  JobsIcon,
  LinkContainer,
  LinkStyled,
  LocationContainer,
  MYCountryFlag,
  MagnifyingGlassIconStyled,
  MenuItemStyled,
  MenuListStyled,
  NavbarContentContainer,
  NavbarLinksStyled,
  PaperStyled,
  PopperStyled,
  RecentSearchText,
  SGCountryFlag,
  SearchDropdownContainer,
  SearchInputContainer,
  SearchSuggestionContainer,
  StyledLoginButton,
  StyledMapPin,
  StyledMenuText,
  StyledOutlinedButton,
  StyledSignUpButton,
  ToolbarStyled,
  TypographyStyled,
  VanillaGrid,
  XMarkIconStyled,
} from "./styles";

function SharedNavbar2(props) {
  const { page } = props;
  const isMobile = useMediaQuery("(max-width: 640px)");

  var typeformId = process.env.NEXT_PUBLIC_WORK_PERSONA_ID;

  const avatarAnchorRef = useRef(null);
  const countryAnchorRef = useRef(null);
  const currentSearch = useSelector((state) => state.jobs?.jobSearchKeyword);
  const reduxCompanyListFilter = useSelector(
    (state) => state.companies?.companyFilter
  );
  const openLoginBool = useSelector((state) => state?.navbar?.showSignInModal);
  const isSigningUp = useSelector((state) => state?.navbar?.isSignUp);
  const showMobileFilter = useSelector(
    (state) => state?.navbar?.showMobileFilter
  );
  // const jobSearchOptionKeyword = useSelector(
  //   (state) => state?.jobs?.jobSearchKeyword
  // );
  const reduxJobListFilter = useSelector((state) => state.jobs?.jobListFilter);
  const reduxUserPersonaId = useSelector(
    (state) => state.user?.workPersona?.attributes?.personaId
  );
  const companyKeyword = reduxCompanyListFilter?.keyword;
  const specialisationList = useSelector((state) => state.jobs?.specialisation);
  const stateList = useSelector((state) => state.jobs?.stateRegions);
  const jobTypeList = useSelector((state) => state.jobs?.jobTypes);

  const [openDrawer, setOpenDrawer] = useState(false);
  const [openSearchDropdown, setOpenSearchDropdown] = useState(false);
  const [openProfilePopper, setOpenProfilePopper] = useState(false);
  const [openCountryPopper, setOpenCountryPopper] = useState(false);
  const [showSearchbar, setShowSearchbar] = useState(false);
  const [isForgetPassword, setIsForgetPassword] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [clearTrigger, setClearTrigger] = useState(0);

  // job preference popup
  const [openNotification, setOpenNotification] = useState(false);

  // TODO: write function for redux hydration for the user profile
  const reduxUser = useSelector(
    (state) => {
      const { user } = state?.user || {};
      const { name, email, pdpaAccepted, profileImage } = user;

      if (Object.keys(user).length < 1) {
        return {};
      }

      return { name, email, pdpaAccepted, profileImage };
    },
    (prev, next) => {
      return (
        prev.name === next.name &&
        prev.email === next.email &&
        prev.pdpaAccepted === next.pdpaAccepted &&
        prev.profileImage === next.profileImage
      );
    }
  );

  const dispatch = useDispatch();

  // breakpoint
  const desktopSize = useMediaQuery("(min-width: 1280px)");
  const tabletSize = useMediaQuery("(min-width: 768px)");

  // adding this for hiredly logo to align center when screen is too big
  const mobileLSize = useMediaQuery("(max-width: 767px)");
  const mobileSize = useMediaQuery("(max-width: 450px)");
  const mobileSizeMin = useMediaQuery("(min-width: 451px)");

  const router = useRouter();

  const popupCount = parseInt(localStorage.getItem("job_preference_clicked"));

  const urlSubdomain = window?.location?.hostname
    .split(".")
    .at(0)
    .toString()
    .toLowerCase();

  useEffect(() => {
    setSearchInput(
      page == "job-list-page" ? currentSearch ?? "" : companyKeyword ?? ""
    );
  }, [reduxCompanyListFilter, currentSearch]);

  useEffect(() => {
    //wait react render the component before auto scrolling
    if (
      (page == "company-list-page" &&
        companyKeyword?.length > 0 &&
        !router.pathname.includes("/companies/")) ||
      reduxCompanyListFilter?.industry_ids ||
      reduxCompanyListFilter?.state_regions
    ) {
      let scrollTimeout;

      if (scrollTimeout) clearTimeout(scrollTimeout);

      scrollTimeout = setTimeout(() => {
        scrollTo(0, 700);
      }, 500);
    }
  }, [router.isReady, handleSearchTermClicked]);

  function getReduxStoreFilter() {
    if (page == "company-list-page") {
      return store.getState().companies.companyFilter;
    } else if (
      page == "user-landing-page" ||
      store.getState().jobs.fromLandingPage === true
    ) {
      return store.getState().jobs.jobListFilterLanding;
    } else {
      return reduxJobListFilter;
    }
  }

  function getEnvCountry() {
    return urlSubdomain.includes("my")
      ? "MY"
      : urlSubdomain.includes("sg")
      ? "SG"
      : "TEST";
  }

  function getSearchbarText() {
    const path = router.pathname;

    return path.includes("/companies") ? "Company Name" : "Discover jobs";
  }

  function calcShowSearchbar() {
    const routePath = router.asPath;

    if (
      routePath.includes("/jobs") ||
      routePath.includes("/companies") ||
      page == "job-list-page"
    ) {
      setShowSearchbar(true);
    } else {
      setShowSearchbar(false);
    }
  }

  function calcShowSearchbarDropdown(event) {
    event.stopPropagation();
    event.preventDefault();

    if (tabletSize) {
      setOpenSearchDropdown(true);
    } else {
      dispatch(updateShowMobileFilter(true));

      const searchOnClickListener = (e) => {
        if (event !== e) {
          window.removeEventListener("click", searchOnClickListener);
        }
      };
    }
  }

  function handleCloseModal() {
    dispatch(updateShowSignInModalStatus(false));
    dispatch(updateSignInModalSignUpStatus(false));

    // in case forget password modal is open, reset to false everytime modal closes
    setIsForgetPassword(false);
  }

  // Perform redux hydration before attempt to fetch user info
  async function handleRecallGetUser() {
    const localStorageUser = localStorage.getItem(types.USER_PROFILE);

    if (localStorageUser) {
      return dispatch(hydrateUserObject(JSON.parse(localStorageUser)));
    }

    // I THINK I FOUND OUT WHY. MUST ALSO CHECK IF LOCALSTORAGE IS NOT FALSEY AS WELL, OTHERWISE IT WILL ALWAYS GET NEW USER INFO
    if (Object.keys(reduxUser ?? {}).length <= 0) {
      return dispatch(newGetUserInfo());
    }

    return;
  }

  const debounceFetchSearchSuggestion = debounce(async (keywordProps) => {
    let params = {
      keyword: keywordProps,
    };
    await dispatch(fetchSearchSuggestions(params));
  }, 300);

  const debounceFetchSearchSuggest = useCallback(
    (value) => debounceFetchSearchSuggestion(value),
    []
  );

  const debounceDispatchKeywordUpdate = debounce((value) => {
    dispatch(updateJobListSearchKeyword({ keyword: value }));
  }, 500);

  function handleSearchSuggestion(event, value) {
    setSearchInput(event?.target?.value);
    const { value: _value } = event?.target || {};

    // prioritise using _value, value as fallback
    const consolidateVal = _value ? _value : value ? value : "";

    if (
      !router.asPath.includes("/companies") &&
      !router.asPath.includes("/company")
    ) {
      debounceFetchSearchSuggest(consolidateVal);
    }

    if (event?.target?.value?.length < 2) {
      return;
    }
  }

  function handleClearSearchInput(event) {
    event?.stopPropagation();
    event?.preventDefault();

    setClearTrigger((prev) => prev + 1);
    setSearchInput("");
    dispatch(updateJobSearchKeyword({ keyword: "" }));
    handleSearchTermClicked("", "nav-bar");
  }

  async function handleSearchTermClicked(searchTerm, type) {
    const pathName = router.asPath;
    setSearchInput(searchTerm);

    if (pathName.includes("/companies") || page == "company-list-page") {
      if (pathName.includes("/companies/")) {
        //when filters are not empty, then clear filter, and apply the filter with only search company keyword
        //to prevent previous filter remains there when user go back to previous page
        if (
          reduxCompanyListFilter?.industry_ids ||
          reduxCompanyListFilter?.state_regions ||
          reduxCompanyListFilter?.company_sizes
        ) {
          let params = {
            type: "industry",
            referral_token: router.query.referral_token,
            keyword: searchTerm,
            industry_ids: [],
            state_regions: [],
            company_sizes: [],
          };
          dispatch(updateCompanyFilter(params));
        }

        if (!isEmpty(searchTerm)) {
          router.push("/companies");
        }
      }

      dispatch(updateCompanySearchKeyword({ keyword: searchTerm }));

      store.getState().companies.isLoadingCompanies = true;
      dispatch(clearCompanyList());

      // const companyFilter = {
      //   ...(store.getState().companies.companyFilter ?? {}),
      //   keyword: searchTerm,
      // };

      const companyFilter = {
        type: reduxCompanyListFilter?.industry_ids
          ? "industry"
          : reduxCompanyListFilter?.state_regions
          ? "location"
          : reduxCompanyListFilter?.company_sizes
          ? "companySize"
          : "industry",
        referral_token: router.query.referral_token,
        keyword: searchTerm,
        industry_ids: reduxCompanyListFilter?.industry_ids,
        state_regions: reduxCompanyListFilter?.state_regions,
        company_sizes: reduxCompanyListFilter?.company_sizes,
      };

      dispatch(updateCompanyFilter(companyFilter));

      // const companyQueryParam = formCompanyQueryParam(companyFilter, true);

      // await dispatch(getTopEightCompanies(companyQueryParam)).then((res) => {
      //   store.getState().companies.isLoadingCompanies = false;
      // })

      // any other page that isn't companies list or company profile page
    } else if (page === "single-job-page") {
      dispatch(updateJobListFilter({ keyword: searchTerm })).then(() => {
        dispatch(updateJobSearchKeyword({ keyword: searchTerm }));
        const keywordSlug =
          searchTerm?.length > 0
            ? encodeURIComponent(
                searchTerm?.toLowerCase()?.replace(/\s+/g, "-")
              ) + "-jobs"
            : "jobs";
        router.push(`/${keywordSlug}`);
      });
    } else if (page === "job-list-page") {
      const keyword = searchTerm ?? currentSearch;
      const currentFilter = store.getState().jobs.jobListFilter;

      const { updatedFilter, foundMatchingResults } = processJobSearchKeyword(
        keyword,
        currentFilter,
        specialisationList,
        stateList,
        jobTypeList
      );

      if (foundMatchingResults) {
        dispatch(updateJobSearchKeyword({ keyword: "" }));
        setSearchInput("");
      } else {
        dispatch(updateJobSearchKeyword({ keyword: keyword }));
      }

      const { path, query } = convertIdsToUrl(
        updatedFilter,
        specialisationList,
        stateList
      );

      router.push({ pathname: path, query }, undefined, { shallow: false });
      dispatch(updateJobListFilter(updatedFilter));
    } else if (searchTerm) {
      const keyword = searchTerm ? searchTerm : currentSearch;
      const query = router.query;

      dispatch(updateJobListFilter({ keyword: keyword })).then(() => {
        dispatch(updateJobSearchKeyword({ keyword: keyword }));
        const { filter, ...queryParams } = query;

        const filteredQuery =
          query.filter.includes("jobs") ||
          query.filter.some((item) => item.endsWith("-jobs"))
            ? query.filter.filter(
                (item) => !item.includes("jobs") && !item.endsWith("-jobs")
              )
            : query.filter;

        const cleanedQuery =
          filteredQuery &&
          filteredQuery.map((item) => {
            if (item.startsWith("jobs-in-")) {
              return item.replace("jobs-", "");
            }
            return item;
          });

        const keywordSlug =
          encodeURIComponent(keyword.toLowerCase()?.replace(/\s+/g, "-")) +
          "-jobs/";
        const slug = keywordSlug + cleanedQuery.join("/");

        if (keyword) {
          // trigger custom event
          if (type == "recent-search") {
            sendTrackingEvent({
              event: "CE_search-job-recent-nav-job-list",
              search_term: keyword,
            });
          } else if (type == "trending-search") {
            sendTrackingEvent({
              event: "CE_search-job-trending-nav-job-list",
              search_term: keyword,
            });
          } else if (type == "suggested-search") {
            sendTrackingEvent({
              event: "CE_search-job-suggestion-nav-job-list",
              search_term: keyword,
            });
          } else if (type == "nav-bar") {
            sendTrackingEvent({
              event: "CE_search-job-nav-job-list",
              search_term: keyword,
            });
          }

          store.getState().jobs.fromJobsListNavBar = true;
        } else {
          // GA tracking custom event
          if (type == "recent-search") {
            store.getState().jobs.fromNavbarRecentSearches = true;
            store.getState().jobs.fromNavbarTrendingSearches = false;
            store.getState().jobs.fromNavbarSuggestedSearches = false;
          } else if (type == "trending-search") {
            store.getState().jobs.fromNavbarRecentSearches = false;
            store.getState().jobs.fromNavbarTrendingSearches = true;
            store.getState().jobs.fromNavbarSuggestedSearches = false;
          } else if (type == "suggested-search") {
            store.getState().jobs.fromNavbarRecentSearches = false;
            store.getState().jobs.fromNavbarTrendingSearches = false;
            store.getState().jobs.fromNavbarSuggestedSearches = true;
          }

          store.getState().navbar.fromNavbarSearch = true;
          router.push("/jobs");
        }

        if (page === "job-list-page") {
          router.push(
            {
              pathname: slug ? slug : "/jobs",
              query: queryParams,
            },
            undefined,
            { shallow: true }
          );
        } else {
          router.push({
            pathname: slug ? slug : "/jobs",
            query: queryParams,
          });
        }
      });
    }
    setOpenSearchDropdown(false);
    dispatch(clearSearchSuggestions());
  }

  useEffect(() => {
    calcShowSearchbar();

    // handle for redux joblistfilter keyword more than 1
    // and fetch search suggestion

    const reduxKeyword = getReduxStoreFilter()["keyword"] ?? "";
    if (reduxKeyword && reduxKeyword.length > 2) {
      handleSearchSuggestion(null, reduxKeyword);
    }

    return () => {
      dispatch(updateSignInModalSignUpStatus(false));
      dispatch(updateShowSignInModalStatus(false));
    };
  }, [router.asPath]);

  // for when user exits profile after visiting for you page
  // prevent for you button in navbar from still being highlighted
  useEffect(() => {
    return () => {
      dispatch(updateUserProfileDrawerIndex(-1));
    };
  }, [router.route]);

  // recall get user info api if redux not already populated
  useEffect(() => {
    if (checkAuthentication()) {
      handleRecallGetUser();

      if (!reduxUser.pdpaAccepted && reduxUser.pdpaAccepted !== undefined) {
        dispatch(checkPDPAStatus());
      }
    }

    if (
      (localStorage?.getItem("apply_origin") ||
        localStorage?.getItem("bulkApplyFromSimilarJob") ||
        localStorage?.getItem("bulkApplyJobId")) &&
      !router.asPath.includes("/apply")
    ) {
      localStorage.removeItem("apply_origin");
      localStorage.removeItem("bulkApplyFromSimilarJob");
      localStorage.removeItem("bulkApplyJobId");
    }
  }, [dispatch]);

  // controls the visibility of the "Jobs" and "Companies" navigation button in the navbar
  function controlNavigateButtonVisible() {
    // if below tablet - don't show
    if (!tabletSize) {
      return false;
      // if desktop, always show
    } else if (desktopSize) {
      return true;
      // if tablet and above, and searchbar is visible
    } else if (tabletSize && showSearchbar) {
      return false;
    } else if (tabletSize && !showSearchbar) {
      return true;
    }
  }

  function conditionShowCountryToggle() {
    if (tabletSize) {
      return true;
    } else if (showSearchbar) {
      if (Object.keys(reduxUser ?? {}).length > 0) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  const searchInputRef = useClickAway(() => {
    tabletSize && setOpenSearchDropdown(false);
  });

  function handleLoginButton() {
    dispatch(updateSignUpOrigin("navbar")).then(() => {
      dispatch(updateShowSignInModalStatus(true));
    });
  }

  function handleSignupButton() {
    dispatch(updateSignUpOrigin("navbar")).then(() => {
      dispatch(updateSignInModalSignUpStatus(true));
      dispatch(updateShowSignInModalStatus(true));
    });
  }

  function handleLocationHref(type) {
    if (type == "login") {
      const url = process?.env?.NEXT_PUBLIC_APP_API_URL;

      if (url && url == "https://staging-my.wobbjobs.com")
        router.replace("https://staging-employer.hiredly.com/login");
      else router.replace("https://employer.hiredly.com/login");
    } else {
      if (process.env.NEXT_PUBLIC_JSW_GEOLOCATION === "sg")
        router.replace("http://hub.hiredly.com/hiredly-sg-employer-waitlist");
      else router.replace(setEmployerParams());
    }
  }

  const user = getUser();
  const isDropResume =
    router.pathname.includes("/drop-resume") ||
    router.pathname.includes("/drop-resume-hiredly-x") ||
    router.pathname.includes("/update-resume") ||
    router.pathname.includes("/apply");

  const showDialog =
    !isEmpty(user) &&
    isEmpty(user?.resume) &&
    !user?.onboardingComplete &&
    !isDropResume;

  const open = useSelector(
    (state) => state.dialog?.showResumeConfirmationDialog
  );

  useEffect(() => {
    setSearchInput(currentSearch || companyKeyword);
  }, [reduxCompanyListFilter, currentSearch]);

  return (
    <Fragment>
      {/* boolean needs to be string because transient prop cannot have boolean */}
      <AppbarStyled
        $employer={router.pathname === "/employer"}
        $homepage={router.pathname === "/" ? "true" : "false"}
      >
        <ToolbarStyled>
          {/* move this here because it acts as divider when display none */}
          <SharedMobileFilterDialog
            clearTrigger={clearTrigger}
            setSearchInput={setSearchInput}
            openSearchDialog={showMobileFilter}
            page={page}
          />
          <NavbarContentContainer>
            {/* Logo and navigation buttons */}
            <VanillaGrid
              display={"flex"}
              gap={"16px"}
              alignItems={"center"}
              width={"auto"}
            >
              <VanillaGrid
                sx={{ display: "flex", alignItems: "center", gap: "16px" }}
              >
                {/* Hamburger menu */}
                {!desktopSize ? (
                  <VanillaGrid
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <IconButtonStyled
                      id="mobile-open-drawer"
                      edge="start"
                      onClick={() => setOpenDrawer(true)}
                      sx={{
                        padding: 0,
                        margin: 0,
                        width: "20px",
                        height: "20px",
                      }}
                    >
                      <Bars3Icon width={20} height={20} color="#512ACC" />
                    </IconButtonStyled>
                  </VanillaGrid>
                ) : null}
                {((isMobile && !showSearchbar) ||
                  (!isMobile && showSearchbar) ||
                  !showSearchbar) && (
                  <VanillaGrid
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: mobileLSize && "100%",
                    }}
                  >
                    <VanillaGrid
                      sx={{
                        display: "flex",
                        alignItems: "baseline",
                        gap: "7.258px",
                        height: "fit-content",
                      }}
                    >
                      <VanillaGrid container width={"100%"}>
                        <a href={"/"} style={{ display: "flex" }}>
                          <HiredlyLogo
                            id="hiredly-logo-button"
                            draggable={false}
                            alt="hiredly-logo"
                            origin={"navbar"}
                          />
                        </a>
                      </VanillaGrid>
                      <TypographyStyled
                        variant="body1"
                        color={Color.hiredlyPurple}
                        fontWeight={600}
                        fontSize={desktopSize ? "12px" : "10px"}
                        textTransform={"uppercase"}
                        sx={{
                          textDecoration: "none",
                          height: "fit-content",
                        }}
                      >
                        {getEnvCountry()}
                      </TypographyStyled>
                    </VanillaGrid>
                  </VanillaGrid>
                )}
              </VanillaGrid>
              {controlNavigateButtonVisible() ? (
                <Fragment>
                  <VanillaGrid
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      gap: "8px",
                    }}
                  >
                    <NavbarLinksStyled
                      id="jobs-tab"
                      href="/jobs"
                      $active={
                        router.pathname.includes(["...filter"]) || //router pathname return array of[..filter], index 0 is jobs
                        (router.pathname.includes("/job-search") &&
                          router.query.slug != "urgent-hiring-jobs")
                      }
                    >
                      jobs
                    </NavbarLinksStyled>

                    <NavbarLinksStyled
                      id="companies-tab"
                      href="/companies"
                      $active={router.pathname === "/companies"}
                    >
                      companies
                    </NavbarLinksStyled>
                  </VanillaGrid>
                </Fragment>
              ) : null}
            </VanillaGrid>

            {/* Search bar */}
            {showSearchbar &&
            (!desktopSize || page !== "job-list-page") &&
            page !== "apply-page" ? (
              <ClickAwayListenerStyled
                onClickAway={() =>
                  !isMobile ? setOpenSearchDropdown(false) : {}
                }
              >
                <VanillaGrid
                  // container
                  ref={searchInputRef}
                  justifyContent={"center"}
                  position={"relative"}
                >
                  <SearchInputContainer
                    value={searchInput}
                    placeholder={getSearchbarText()}
                    onClick={calcShowSearchbarDropdown}
                    readOnly={mobileSize}
                    endAdornment={
                      <IconButtonStyled
                        sx={{
                          width: "24px",
                          height: "24px",
                          justifyContent: "center",
                          alignItems: "center",
                          flexShrink: 0,
                          backgroundColor: "#512acc",
                          padding: "4px",

                          "@media(min-width: 1280px)": {
                            width: "36px",
                            height: "36px",
                            padding: "8.1px 7.807px 7.807px 8.1px",
                          },

                          ":hover": {
                            backgroundColor: "black",
                          },
                        }}
                        onClick={(event) => {
                          companyKeyword
                            ? handleClearSearchInput(event)
                            : handleSearchTermClicked(searchInput, "nav-bar");
                        }}
                      >
                        {companyKeyword ? (
                          <XMarkIconStyled color="white" />
                        ) : (
                          <MagnifyingGlassIconStyled color="white" />
                        )}
                      </IconButtonStyled>
                    }
                    onChange={(event) => handleSearchSuggestion(event)}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        handleSearchTermClicked(
                          event?.target?.value ?? "",
                          "nav-bar"
                        );
                      }
                    }}
                  />

                  {/* ONLY SHOWS ONCE TABLET AND ABOVE */}
                  {openSearchDropdown &&
                  !router.asPath.includes("/companies") &&
                  !isMobile ? (
                    <SearchDropdown
                      handleSearchTermClicked={handleSearchTermClicked}
                      // setOpenSearchDropdown={setOpenSearchDropdown} // probs not best practice, open for suggestion. //exist before [JSW-3148]
                    />
                  ) : null}
                </VanillaGrid>
              </ClickAwayListenerStyled>
            ) : null}

            {/* Right side - login and sign up */}
            {router.pathname === "/employer" ? (
              <Fragment>
                {!isMobile && (
                  <CTAContainer>
                    {conditionShowCountryToggle() && desktopSize && (
                      <ClickAwayListenerStyled
                        onClickAway={() =>
                          !openDrawer && setOpenCountryPopper(false)
                        }
                      >
                        <LocationContainer
                          onClick={() =>
                            setOpenCountryPopper(!openCountryPopper)
                          }
                          ref={countryAnchorRef}
                        >
                          <StyledMapPin />
                          <CountryText>
                            {urlSubdomain.includes("sg") ? "SG" : "MY"}
                          </CountryText>
                          <CountryTogglePopper
                            countryPopBool={openCountryPopper}
                            countryAnchorRef={countryAnchorRef}
                            subdomain={urlSubdomain}
                            router={router}
                          />
                        </LocationContainer>
                      </ClickAwayListenerStyled>
                    )}
                    <VanillaGrid
                      sx={{ display: "flex", alignItems: "center", gap: "8px" }}
                    >
                      <StyledLoginButton
                        href={
                          process?.env?.NEXT_PUBLIC_APP_API_URL &&
                          process.env.NEXT_PUBLIC_APP_API_URL ==
                            "https://staging-my-api.hiredly.com"
                            ? "https://staging-employer.hiredly.com/login"
                            : "https://employer.hiredly.com/login"
                        }
                        variant="text"
                        id="employer-login-button"
                      >
                        Employer Log in
                      </StyledLoginButton>

                      <StyledSignUpButton
                        href={
                          process.env.NEXT_PUBLIC_JSW_GEOLOCATION === "sg"
                            ? "http://hub.hiredly.com/hiredly-sg-employer-waitlist"
                            : setEmployerParams()
                        }
                        variant="text"
                        id="start-hiring-today-button"
                      >
                        Employer Sign Up
                      </StyledSignUpButton>
                    </VanillaGrid>
                    <DividerLine />
                    <StyledOutlinedButton
                      onClick={() => {
                        router.push("/jobs");
                      }}
                    >
                      Find Jobs
                    </StyledOutlinedButton>
                  </CTAContainer>
                )}
              </Fragment>
            ) : Object.keys(reduxUser ?? {}).length > 0 ? ( //if logged in
              <Fragment>
                <VanillaGrid
                  sx={{
                    display: "flex",
                    gap: tabletSize ? "20px" : "16px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {tabletSize && (
                    <StyledOutlinedButton
                      id="for-employers-button"
                      onClick={() => {
                        router.push("/employer");
                      }}
                      $active={router.pathname === "/employer"}
                    >
                      for employers
                    </StyledOutlinedButton>
                  )}

                  {tabletSize && <DividerLine />}

                  {!isMobile && (
                    <ClickAwayListenerStyled
                      onClickAway={() =>
                        !openDrawer && setOpenCountryPopper(false)
                      }
                    >
                      <LocationContainer
                        onClick={() => setOpenCountryPopper(!openCountryPopper)}
                        ref={countryAnchorRef}
                      >
                        <StyledMapPin />
                        <CountryText>
                          {urlSubdomain.includes("sg") ? "SG" : "MY"}
                        </CountryText>
                        <CountryTogglePopper
                          countryPopBool={openCountryPopper}
                          countryAnchorRef={countryAnchorRef}
                          subdomain={urlSubdomain}
                          router={router}
                        />
                      </LocationContainer>
                    </ClickAwayListenerStyled>
                  )}

                  <ClickAwayListenerStyled
                    onClickAway={() => setOpenProfilePopper(false)}
                  >
                    <VanillaGrid
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "2px",
                      }}
                      onClick={() => setOpenProfilePopper(!openProfilePopper)}
                    >
                      <VanillaGrid>
                        <AvatarStyled
                          id="user-profile-avatar"
                          alt="user-profile-image"
                          ref={avatarAnchorRef}
                          src={reduxUser?.profileImage ?? ""}
                        />
                        <ProfilePopper
                          profilePopBool={openProfilePopper}
                          profileAnchorRef={avatarAnchorRef}
                        />
                      </VanillaGrid>
                      {openProfilePopper ? (
                        <ChevronUpStyled
                          sx={{ width: "20px", height: "20px" }}
                        />
                      ) : (
                        <ChevronDownStyled
                          sx={{ width: "20px", height: "20px" }}
                        />
                      )}
                    </VanillaGrid>
                  </ClickAwayListenerStyled>
                </VanillaGrid>
              </Fragment>
            ) : (
              <Fragment>
                {/* CONDITION 1: IF mobile screen size, and search bar is not showing -> SHOW LOGIN */}
                {/* CONDITION 2: IF tablet and above -> SHOW LOGIN */}
                <VanillaGrid
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "20px",
                  }}
                >
                  {!isMobile && (
                    <ClickAwayListenerStyled
                      onClickAway={() =>
                        !openDrawer && setOpenCountryPopper(false)
                      }
                    >
                      <LocationContainer
                        onClick={() => setOpenCountryPopper(!openCountryPopper)}
                        ref={countryAnchorRef}
                      >
                        <StyledMapPin />
                        <CountryText>
                          {urlSubdomain.includes("sg") ? "SG" : "MY"}
                        </CountryText>
                        <CountryTogglePopper
                          countryPopBool={openCountryPopper}
                          countryAnchorRef={countryAnchorRef}
                          subdomain={urlSubdomain}
                          router={router}
                        />
                      </LocationContainer>
                    </ClickAwayListenerStyled>
                  )}
                  <VanillaGrid gap={"8px"} display={"flex"}>
                    <StyledLoginButton onClick={() => handleLoginButton()}>
                      Log In
                    </StyledLoginButton>
                    {!isMobile && (
                      <StyledSignUpButton onClick={() => handleSignupButton()}>
                        Sign Up
                      </StyledSignUpButton>
                    )}
                  </VanillaGrid>

                  {/* if (tabletSize and visitor) and (not /companies and not /jobs) or (desktopSize and (is /companies and is /jobs))
                  First condition: show when minWidth = 640, visitor, not /companies and, not /jobs
                  Second condition: show when minWidth = 1280 and is /companies or /jobs */}

                  {((!isMobile &&
                    !checkAuthentication() &&
                    !router.pathname.includes("/companies") &&
                    !router.pathname.includes(["...filter"])) ||
                    (desktopSize &&
                      (router.pathname.includes("/companies") ||
                        router.pathname.includes(["...filter"])))) && (
                    <DividerLine />
                  )}

                  {((!isMobile &&
                    !checkAuthentication() &&
                    !router.pathname.includes("/companies") &&
                    !router.pathname.includes(["...filter"])) ||
                    (desktopSize &&
                      (router.pathname.includes("/companies") ||
                        router.pathname.includes(["...filter"])))) && (
                    <StyledOutlinedButton
                      id="for-employers-button"
                      onClick={() => {
                        router.push("/employer");
                      }}
                      $active={router.pathname === "/employer"}
                    >
                      for employers
                    </StyledOutlinedButton>
                  )}
                </VanillaGrid>
              </Fragment>
            )}
            {/* </VanillaGrid> */}
            {isMobile &&
              router.pathname == "/employer" && ( //maxwidth 640px [AND] current page == employer
                <StyledLoginButton
                  onClick={() => {
                    handleLocationHref();
                  }}
                >
                  Employer Log in
                </StyledLoginButton>
              )}
          </NavbarContentContainer>
        </ToolbarStyled>

        {/* popup drawer for tablet and below */}
        <Drawer
          PaperProps={{
            sx: {
              width: "100%",
              maxWidth: "640px",
              gap: "40px",
              padding: "24px",

              "@media(max-width: 767px)": {
                padding: "24px 24px 40px 24px",
              },
            },
          }}
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
        >
          <VanillaGrid
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              alignSelf: "stretch",
            }}
          >
            <VanillaGrid
              sx={{ display: "flex", alignItems: "baseline", gap: "7.258px" }}
            >
              <VanillaGrid
                container
                alignItems={"end"}
                columnGap={"5px"}
                width={"100%"}
              >
                <a href={"/"} style={{ display: "flex" }}>
                  <HiredlyLogo
                    id="hiredly-logo-button"
                    draggable={false}
                    alt="hiredly-logo"
                  />
                </a>
              </VanillaGrid>
              <TypographyStyled
                variant="body1"
                color={Color.hiredlyPurple}
                fontWeight={600}
                fontSize={desktopSize ? "12px" : "10px"}
                textTransform={"uppercase"}
                sx={{
                  textDecoration: "none",
                }}
              >
                {getEnvCountry()}
              </TypographyStyled>
            </VanillaGrid>
            <IconButtonStyled
              onClick={() => setOpenDrawer(false)}
              sx={{ padding: 0 }}
            >
              <XMarkIcon width={32} height={32} color="#222222" />
            </IconButtonStyled>
          </VanillaGrid>
          <DrawerContentContainer>
            <VanillaGrid>
              <StyledMenuText>jobseekers</StyledMenuText>
            </VanillaGrid>

            <LinkStyled
              href="/jobs"
              width={"100%"}
              onClick={() =>
                sendTrackingEvent({ event: "CE_click-navigation-jobs-navbar" })
              }
            >
              <LinkContainer container>Jobs</LinkContainer>
            </LinkStyled>
            <LinkStyled
              href="/companies"
              width={"100%"}
              onClick={() =>
                sendTrackingEvent({
                  event: "CE_click-navigation-companies-navbar",
                })
              }
            >
              <LinkContainer container>Companies</LinkContainer>
            </LinkStyled>
            {!router.pathname.includes("/employer") && !checkAuthentication() && (
              <VanillaGrid
                sx={{
                  display: "flex",
                  maxWidth: "400px",
                  width: "100%",
                  gap: "8px",
                  alignItems: "flex-start",
                }}
              >
                <DrawerLoginButton onClick={() => handleLoginButton()}>
                  Log in
                </DrawerLoginButton>
                <DrawerSignUpButton onClick={() => handleSignupButton()}>
                  Sign Up
                </DrawerSignUpButton>
              </VanillaGrid>
            )}
            <HorizontalDividerLine />

            <VanillaGrid>
              <StyledMenuText>employers</StyledMenuText>
            </VanillaGrid>

            <VanillaGrid container>
              <LinkStyled href="/employer" width={"100%"}>
                <LinkContainer container>For Employers</LinkContainer>
              </LinkStyled>
            </VanillaGrid>

            {router.pathname.includes("/employer") && (
              <VanillaGrid
                container
                justifyContent={"flex-start"}
                sx={{ gap: "8px" }}
                maxWidth={"400px"}
              >
                <DrawerLoginButton
                  href={
                    process?.env?.NEXT_PUBLIC_APP_API_URL &&
                    process.env.NEXT_PUBLIC_APP_API_URL ==
                      "https://staging-my-api.hiredly.com"
                      ? "https://staging-employer.hiredly.com/login"
                      : "https://employer.hiredly.com/login"
                  }
                  variant="text"
                  id="employer-login-button"
                >
                  Log in
                </DrawerLoginButton>
                <DrawerSignUpButton
                  href={
                    process.env.NEXT_PUBLIC_JSW_GEOLOCATION === "sg"
                      ? "http://hub.hiredly.com/hiredly-sg-employer-waitlist"
                      : setEmployerParams()
                  }
                  variant="text"
                  id="start-hiring-today-button"
                >
                  Sign Up
                </DrawerSignUpButton>
              </VanillaGrid>
            )}

            <HorizontalDividerLine />

            {/* COUNTRY TOGGLE STARTS HERE */}
            <VanillaGrid container>
              <DrawerCountryOption
                mobileSize={mobileSize}
                openCountryPopper={openCountryPopper}
                setOpenCountryPopper={setOpenCountryPopper}
                subdomain={urlSubdomain}
                router={router}
              />
              {/* COUNTRY TOGGLE ENDS HERE */}
            </VanillaGrid>
          </DrawerContentContainer>
        </Drawer>

        {/* Login / Sign up dialog */}
        <Login
          open={openLoginBool}
          handleClose={handleCloseModal}
          signUp={isSigningUp}
          handleClickSignUp={() =>
            dispatch(updateSignInModalSignUpStatus(true))
          }
          handleClickSignIn={() =>
            dispatch(updateSignInModalSignUpStatus(false))
          }
          forgotPassword={isForgetPassword}
          handleOpenForgotPassword={() => setIsForgetPassword(true)}
        />
        {/* Putting this here as Login will unmount everytime the Login dialog closes. */}
        {/* Cannot declare the below dialog in Login */}
        {/* for filling basic info and attaching resume to build profile */}
        <SignUpProfileDialog />
        {/* for preview profile after reading basic info and resume from above */}
        <ProfilePreviewDialog />

        <SharedPrivacyDialog />

        {/* Job Preference popup */}
        <SharedJobPreferenceNotification
          popupCount={popupCount}
          openNotification={openNotification}
          setOpenNotification={setOpenNotification}
        />

        {router.pathname === "/" && !desktopSize && (
          <HomepageButtonContainer
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <HomepageButton
              id="mobile-jobs-button"
              jobs_button={true}
              onClick={() => {
                sendTrackingEvent({ event: "CE_click-jobs-homepage" });
                router.push("/jobs");
              }}
            >
              <JobsIcon />
              Jobs
            </HomepageButton>
            <HomepageButton
              id="mobile-companies-button"
              onClick={() => {
                sendTrackingEvent({ event: "CE_click-companies-homepage" });
                router.push("/companies");
              }}
            >
              <CompanyIcon />
              Companies
            </HomepageButton>
          </HomepageButtonContainer>
        )}
      </AppbarStyled>

      {/* Snackbar declaration */}
      <SharedSnackbar />
    </Fragment>
  );
}

function ProfilePopper({ profilePopBool, profileAnchorRef }) {
  const reduxUserName = useSelector((state) => state?.user?.user?.name);

  const router = useRouter();

  const dispatch = useDispatch();

  async function onSignoutClicked() {
    const logoutRes = await dispatch(logout());

    if (logoutRes.type === types.LOGOUT) {
      Cookies.remove("userId"); // Delete cookie for user ID sent to GA when user logs out
      Cookies.set("isRegistered", false, { expires: 365 }); // Check if user is logged out and change cookie to false for CleverTap
      Cookies.remove("token");

      sendTrackingEvent({ event: "CE_logout" });

      localStorage.removeItem("shown_resume_banner_highlight"); // Remove the key that determines whether show resume banner highlight upon log out
      localStorage.removeItem("show-tacf-/profile");
      localStorage.removeItem("show-tacf-/companies");
      localStorage.removeItem("show-tacf-/jobs");

      sessionStorage.removeItem("fraud-warning");

      if (router.pathname === "/") {
        router.reload();
      } else {
        router.push("/");
      }
    }
  }

  return (
    <PopperStyled
      open={profilePopBool}
      anchorEl={profileAnchorRef.current}
      role={undefined}
      transition
      disablePortal
    >
      {({ TransitionProps }) => (
        <GrowStyled {...TransitionProps}>
          <PaperStyled>
            <MenuListStyled width={"100%"}>
              <MenuItemStyled
                sx={{
                  fontWeight: "700",
                }}
              >
                {reduxUserName}
              </MenuItemStyled>
              <MenuItemStyled
                id={"user-view-profile-button"}
                onClick={() => {
                  dispatch(updateUserProfileDrawerIndex(0));
                  router.push("/profile#overview");
                }}
                selected={
                  router.pathname === "/profile" &&
                  router.asPath.includes("overview")
                }
              >
                Profile
              </MenuItemStyled>
              <MenuItemStyled
                id={"user-manage-job-alerts-button"}
                onClick={() => {
                  dispatch(updateUserProfileDrawerIndex(2));
                  router.push("/profile#manage-job-alerts");
                }}
                selected={
                  router.pathname === "/profile" &&
                  router.asPath.includes("manage-job-alerts")
                }
              >
                Job Alerts
              </MenuItemStyled>
              <MenuItemStyled
                id={"user-job-application-history-button"}
                onClick={() => {
                  router.push("/profile#job-application-history");
                  dispatch(updateUserProfileDrawerIndex(5));
                }}
                selected={
                  router.pathname === "/profile" &&
                  router.asPath.includes("job-application-history")
                }
              >
                Job Application Status
              </MenuItemStyled>
              <MenuItemStyled id="log-out-button" onClick={onSignoutClicked}>
                Logout
              </MenuItemStyled>
            </MenuListStyled>
          </PaperStyled>
        </GrowStyled>
      )}
    </PopperStyled>
  );
}

function CountryTogglePopper({ countryPopBool, countryAnchorRef, router }) {
  return (
    <PopperStyled
      open={countryPopBool}
      anchorEl={countryAnchorRef.current}
      role={undefined}
      transition
      disablePortal
    >
      {({ TransitionProps }) => (
        <CountryGrowStyled {...TransitionProps}>
          <PaperStyled>
            <MenuListStyled width={"100%"}>
              <CountryMenuItem
                onClick={() =>
                  handleSwitchCountry({ country: "my", router: router })
                }
              >
                <VanillaGrid
                  sx={{
                    display: "flex",
                    width: "24px",
                    height: "24px",
                    padding: "3.429px 0px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <MYCountryFlag isPopper />
                </VanillaGrid>
                <CountryOptionName variant="body1">MY</CountryOptionName>
              </CountryMenuItem>
              <CountryMenuItem
                onClick={() =>
                  handleSwitchCountry({ country: "sg", router: router })
                }
              >
                <SGCountryFlag isPopper />
                <CountryOptionName variant="body1">SG</CountryOptionName>
              </CountryMenuItem>
            </MenuListStyled>
          </PaperStyled>
        </CountryGrowStyled>
      )}
    </PopperStyled>
  );
}

function SearchDropdown({ handleSearchTermClicked }) {
  const [recentSearchState, setRecentSearchState] = useState([]);

  // redux related
  const reduxTrendingKeywords = useSelector(
    (state) => state?.jobs?.trendingJobKeywords
  );
  const jobSearchKeyword = useSelector(
    (state) => state.jobs?.jobListFilter?.keyword
  );
  const currentSearch = useSelector((state) => state?.jobs?.jobSearchKeyword);
  const jobSearchData = useSelector((state) => state?.jobs?.searchData);

  const dispatch = useDispatch();

  function boldMatchingText(suggestion) {
    const keyword = currentSearch.toLowerCase();

    const lowerCaseSuggestion = suggestion.toLowerCase();
    const index = lowerCaseSuggestion.indexOf(keyword);

    if (!keyword || index === -1) {
      return (
        <SearchSuggestionContainer
          onClick={() => handleSearchTermClicked(suggestion, "nav-bar")}
        >
          {suggestion}
        </SearchSuggestionContainer>
      );
    }

    const beforeMatch = suggestion.substring(0, index);
    const match = suggestion.substring(index, index + keyword.length);
    const afterMatch = suggestion.substring(index + keyword.length);

    return (
      <SearchSuggestionContainer
        onClick={() => handleSearchTermClicked(suggestion, "nav-bar")}
      >
        <span>
          {beforeMatch}
          <b>{match}</b>
          {afterMatch}
        </span>
      </SearchSuggestionContainer>
    );
  }

  function deleteRecentSearch(value) {
    let updatedSearches = recentSearchState.filter(
      (recentSearch) => recentSearch.query !== value
    );
    setRecentSearchState(updatedSearches);
    setItem("recent-searches", JSON.stringify(updatedSearches));
  }

  useEffect(() => {
    // for recent search keywords
    setRecentSearchState(JSON.parse(getItem("recent-searches")));

    // handle for re-fetch trending keyword at least once
    if (
      Array.isArray(reduxTrendingKeywords) &&
      reduxTrendingKeywords.length < 1
    ) {
      dispatch(getTrendingJobKeywords({ limit: 10 }));
    }
  }, []);

  useEffect(() => {
    const debounceFetchRecentSearch = debounce(() => {
      setRecentSearchState(JSON.parse(getItem("recent-searches")));
    }, 400);

    debounceFetchRecentSearch(jobSearchKeyword);

    return () => {
      setRecentSearchState([]);
    };
  }, [jobSearchKeyword]);

  // FULFILLMENT CONDITION:
  // state.job.jobListFilter.keyword needs to be prepopulated before landing on job list page
  // handleSearchSuggestion() will trigger automatically in useEffect
  // causing the initial render to go into the first condition, instead of displaying recent and trending keyword.
  return jobSearchKeyword?.length > 2 && jobSearchData?.length > 0 ? (
    <SearchDropdownContainer>
      {jobSearchData.map((data, index) => (
        <div key={index}>{boldMatchingText(data.name)}</div>
      ))}
    </SearchDropdownContainer>
  ) : (
    <SearchDropdownContainer>
      {/* recent searches */}
      {recentSearchState.length > 0 ? (
        <Fragment>
          <RecentSearchText>Recent Searches</RecentSearchText>
          <VanillaGrid container flexDirection={"column"} margin={"10px 0"}>
            {recentSearchState.map((recentSearch) => {
              return (
                <VanillaGrid
                  container
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  sx={{
                    ":hover": {
                      backgroundColor: "#efefef",
                    },
                    cursor: "pointer",
                  }}
                  borderRadius={"15px"}
                  onClick={() =>
                    handleSearchTermClicked(recentSearch.query, "recent-search")
                  }
                >
                  <VanillaGrid
                    container
                    columnGap={"12px"}
                    alignItems={"center"}
                    maxWidth={"80%"}
                  >
                    <ClockIcon
                      width={24}
                      height={24}
                      color="rgba(132, 132, 132, 0.6)"
                    />
                    <RecentSearchText sx={{ fontWeight: "400" }}>
                      {recentSearch.query}
                    </RecentSearchText>
                  </VanillaGrid>
                  <VanillaGrid>
                    <IconButtonStyled
                      onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        deleteRecentSearch(recentSearch.query);
                      }}
                    >
                      <TrashIcon
                        width={24}
                        height={24}
                        color="rgba(132, 132, 132, 0.6)"
                      />
                    </IconButtonStyled>
                  </VanillaGrid>
                </VanillaGrid>
              );
            })}
          </VanillaGrid>
        </Fragment>
      ) : null}

      {/* trending keyword */}
      {reduxTrendingKeywords?.length > 0 ? (
        <Fragment>
          <RecentSearchText>Trending Searches</RecentSearchText>
          <VanillaGrid container flexWrap={"wrap"} flexDirection={"row"}>
            {Array.isArray(reduxTrendingKeywords) &&
              reduxTrendingKeywords.slice(0, 4).map((keyword, index) => {
                return (
                  <ChipStyled
                    icon={<MagnifyingGlassIconStyled color="black" />}
                    label={keyword}
                    key={index}
                    onClick={() =>
                      handleSearchTermClicked(keyword, "trending-search")
                    }
                  />
                );
              })}
          </VanillaGrid>
        </Fragment>
      ) : null}
    </SearchDropdownContainer>
  );
}

function DrawerCountryOption({
  mobileSize,
  openCountryPopper,
  setOpenCountryPopper,
  subdomain,
  router,
}) {
  return (
    <DrawerCountryToggleGrid container mobileSize={mobileSize}>
      {/* dropdown current option */}
      <VanillaGrid
        container
        sx={{
          cursor: "pointer",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          alignSelf: "stretch",
          width: "100%",
        }}
        onClick={() => {
          setOpenCountryPopper(!openCountryPopper);
        }}
        zIndex={100}
      >
        <VanillaGrid
          sx={{
            display: "flex",
            gap: "8px",
            alignItems: "center",
          }}
        >
          <StyledMapPin />
          {!subdomain.includes("sg") ? (
            <DrawerCountryText>Malaysia</DrawerCountryText>
          ) : (
            <DrawerCountryText>Singapore</DrawerCountryText>
          )}

          {/* {!subdomain.includes("sg") ? "Malaysia" : "Singapore"} */}
        </VanillaGrid>
        {openCountryPopper ? <ChevronUpStyled /> : <ChevronDownStyled />}
      </VanillaGrid>

      <VanillaGrid
        sx={{
          display: "flex",
          padding: "0px 24px",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "24px",
        }}
      >
        {/* Malaysia option */}
        <VanillaGrid
          container
          display={openCountryPopper ? "flex" : "none"}
          sx={{
            cursor: "pointer",
            width: "109px",
            alignItems: "center",
            gap: "18px",
            ":hover": {
              backgroundColor: Color.lightGrey,
            },
          }}
          onClick={() => handleSwitchCountry({ country: "my", router: router })}
          flexWrap={"nowrap"}
        >
          <DrawerDropdownRadio checked={!subdomain.includes("sg")} />
          <VanillaGrid
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              flex: "1 0 0",
            }}
          >
            <VanillaGrid
              sx={{
                display: "flex",
                width: "24px",
                height: "24px",
                padding: "3.429px 0px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <MYCountryFlag isPopper />
            </VanillaGrid>
            Malaysia
          </VanillaGrid>
        </VanillaGrid>

        {/* Singapore option */}
        <VanillaGrid
          container
          display={openCountryPopper ? "flex" : "none"}
          sx={{
            cursor: "pointer",
            width: "109px",
            alignItems: "center",
            gap: "18px",
            ":hover": {
              backgroundColor: Color.lightGrey,
            },
          }}
          onClick={() => handleSwitchCountry({ country: "sg", router: router })}
          flexWrap={"nowrap"}
        >
          <DrawerDropdownRadio checked={subdomain.includes("sg")} />
          <VanillaGrid
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              flex: "1 0 0",
            }}
          >
            <VanillaGrid
              sx={{
                display: "flex",
                width: "24px",
                height: "24px",
                padding: "3.429px 0px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <SGCountryFlag isPopper />
            </VanillaGrid>
            Singapore
          </VanillaGrid>
        </VanillaGrid>
      </VanillaGrid>
    </DrawerCountryToggleGrid>
  );
}

export default WithOnboarding(SharedNavbar2);
