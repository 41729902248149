import { useDispatch } from "react-redux";
import { hideSnackBar, showSnackBar } from "../redux/actions/snackbar_action";
import { store } from "../redux/stores/store";

/**
 *
 * @param {String} snackbarMessage
 * @param {String} severity
 * @param {String} fallbackMessage
 * @returns
 */
export default function useTriggerSnackbar() {
  const dispatch = useDispatch();

  function triggerSnackbarFunc(funcProp) {
    const {
      snackbarMessage,
      severity,
      fallbackMessage,
      boolShowAction,
      isMoreThan2MB,
      accountCreated,
    } = funcProp;

    const snackbarParams = {
      message: snackbarMessage ?? `${fallbackMessage}`,
      severity: severity,
      showActionButton: boolShowAction,
      isMoreThan2MB: isMoreThan2MB,
      accountCreated: accountCreated,
    };

    if (store.getState().snackbar.showSnackBar) {
      dispatch(hideSnackBar()).then(() => {
        setTimeout(() => {
          dispatch(showSnackBar(snackbarParams));
        }, 500);
      });
    } else {
      dispatch(showSnackBar(snackbarParams)).then(() => {
        setTimeout(() => {
          dispatch(hideSnackBar());
        }, 2000);
      });
    }
  }

  return [triggerSnackbarFunc];
}
