import ExclamationCircleIcon from "@heroicons/react/24/outline/ExclamationCircleIcon";
import { styled as styling } from "@mui/material/styles";
import { ToastContainer } from "react-toastify";
import Color from "../../../assets/colors";

export const ExclamationIcon = styling(ExclamationCircleIcon)({
  strokeWidth: "2",
  width: "16px",
  height: "16px",
});

export const ToastStyled = styling(ToastContainer)({
  "&.Toastify__toast-container": {
    width: "346px",
    padding: "0.625rem 0.5rem 0.5rem 1rem",
  },

  "& .Toastify__toast-body": {
    padding: "0.15rem 1rem 0.35rem 0",
  },

  "& .Toastify__toast": {
    borderRadius: "0.5rem",
    fontSize: "0.875rem",
    fontWeight: "400",
    lineHeight: "1.25rem",

    minHeight: "36px",
    padding: "0.6rem 0.625rem 0.4rem",
  },

  "& .Toastify__toast--success": {
    border: `1px solid rgba(54, 118, 35, 1)`,
    color: "rgba(54, 118, 35, 1)",

    "& .Toastify__close-button": {
      color: "rgba(54, 118, 35, 1)",
      opacity: 1,
      position: "relative",
      padding: 0,
      alignSelf: "center",
    },

    "& .Toastify__progress-bar--success": {
      backgroundColor: Color.success,
      height: "5px",
    },
  },

  "& .Toastify__toast--error": {
    border: `1px solid ${Color.error}`,
    color: Color.error,

    "& .Toastify__close-button": {
      color: Color.error,
      opacity: 1,
      position: "relative",
      padding: 0,
      alignSelf: "center",
    },

    "& .Toastify__progress-bar--error": {
      backgroundColor: Color.error,
      height: "5px",
    },
  },
});
