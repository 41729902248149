import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendTrackingEvent } from "../../../helpers/tracking_management";
import { updateSignUpProfileDialog } from "../../../redux/actions/dialog_action";
import {
  ButtonStyled,
  CloseButton,
  CloseIcon,
  DialogContainer,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from "./styles";

export default function SharedExitDialog(props) {
  const {
    triggerExitDialog,
    setTriggerExitDialog,
    isApplyFlow = false,
  } = props;

  const router = useRouter();
  const dispatch = useDispatch();

  const [showDialog, setShowDialog] = useState(false);

  const bulkAppliedJobs = useSelector((state) => state?.jobs?.bulkAppliedJobs);

  const originUrl = localStorage.getItem("apply_origin");

  function handleClose(action) {
    setShowDialog(false);
    if (triggerExitDialog) {
      setTriggerExitDialog(false);
    }
    sendTrackingEvent({
      event: "CE_signup_easy_apply_back_click",
      colour: "-",
      cta: action,
    });
  }

  function handleExit() {
    // Close exit confirmation dialog if triggered from sign up profile dialog

    // Remove this local storage if visitor exits from bulk job apply

    if (triggerExitDialog) {
      setTriggerExitDialog(false);
      dispatch(updateSignUpProfileDialog(false));
      sendTrackingEvent({
        event: "CE_onboarding_skip_click",
        origin: "onboarding",
      });
      sessionStorage.removeItem("triggerSignUpProfileDialog");
      router.push("/jobs");
      return;
    }

    setShowDialog(false);
    const getBulkJobApply = localStorage.getItem("bulkApplyFromSimilarJob");

    if (getBulkJobApply) {
      sendTrackingEvent({
        event: "CE_view_similar_jobs_exit_click",
        colour: "red",
        cta: "Exit",
      });
      localStorage.removeItem("bulkApplyFromSimilarJob");
    } else {
      sendTrackingEvent({
        event: "CE_signup_easy_apply_exit_click",
        colour: "red",
        cta: "Exit",
      });
    }

    if (originUrl) {
      localStorage.removeItem("apply_origin");
      router.push(originUrl);
    } else {
      router.back();
    }
  }

  useEffect(() => {
    // Early return conditions
    if (
      !isApplyFlow ||
      bulkAppliedJobs?.length > 0 ||
      !router.pathname.startsWith("/apply")
    ) {
      return;
    }

    let isLeaving = false;

    const handleBeforeUnload = (event) => {
      if (localStorage.getItem("scraped_job_id")) {
        localStorage.removeItem("scraped_job_id");
        return;
      }
      event.preventDefault();
      isLeaving = true;
      setTimeout(() => {
        if (isLeaving) {
          sendTrackingEvent({
            event: "CE_signup_easy_apply_back_click",
            colour: "-",
            cta: "Back",
          });
          isLeaving = false;
        }
      }, 1000);
      return event.returnValue;
    };

    const handlePopState = (event) => {
      event.preventDefault();
      history.pushState(null, "", window.location.href);
      setShowDialog(true);
    };

    const handlePageHide = () => {
      if (isLeaving) {
        sendTrackingEvent({
          event: "CE_signup_easy_apply_exit_click",
          colour: "red",
          cta: "Exit",
        });
        isLeaving = false;
      }
    };

    history.pushState(null, "", window.location.href);

    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("popstate", handlePopState);
    window.addEventListener("pagehide", handlePageHide);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("popstate", handlePopState);
      window.removeEventListener("pagehide", handlePageHide);
    };
  }, [router, bulkAppliedJobs]);

  return (
    <DialogContainer
      open={showDialog || triggerExitDialog}
      onClose={() => handleClose("X")}
    >
      <DialogHeader>
        Are you sure you want to exit?
        <CloseButton onClick={() => handleClose("X")}>
          <CloseIcon />
        </CloseButton>
      </DialogHeader>
      <DialogContent>
        Your information given during this application will not be saved.
      </DialogContent>
      <DialogFooter>
        <ButtonStyled onClick={() => handleClose("Back")}>Back</ButtonStyled>
        <ButtonStyled variant="filled" onClick={handleExit}>
          Exit
        </ButtonStyled>
      </DialogFooter>
    </DialogContainer>
  );
}
